<template>
  <transition name="fade">
    <FullScreenModal v-if="isBuyFlowShow" title="購物流程" @back="closePolicy">
      <div class="container py-3">
        <p>
          一、購物流程<br/>
          <br/>
          單次媒合服務流程如下：<br/>
          選擇服務並完成加購<br/>
          依據需求送出衣物資料<br/>
          等待服務提供夥伴報價<br/>
          確認報價後，依需求寄出衣物<br/>
          等待收件確認<br/>
          <br/>
          二、付款方式<br/>
          <br/>
          僅提供線上刷卡（VISA、MASTER、JCB 皆可使用）及 LINE pay。<br/>
          <br/>
          三、商品/服務性質<br/>
          <br/>
          本網站販售之商品/服務皆為非以有形媒介提供之數位內容或一經提供即為完成之線上服務，為特殊性質商品。<br/>
          <br/>
          四、發票開立<br/>
          <br/>
          您可以選擇以下三擇一方式取得發票：<br/>
          電子發票（雲端發票）：系統會在您付款後的7個工作天內開立發票，並寄送至您的 email 信箱（開獎後會自動為您兌獎，如您中獎，會將發票印出、並以掛號寄送給您）<br/>
          捐贈：若選擇捐贈，系統會自動將您的發票捐至合作的非營利組織（協會或基金會）<br/>
          公司戶紙本發票：根據財政部令「電子發票實施作業要點」，於本網站消費皆開立電子發票，若您有開立統編報帳的需求，請於結帳時輸入統一編號和發票抬頭，我們將會寄出您的公司戶電子發票至您的 email 信箱。<br/>
        </p>
        
      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'BuyFlowModal',
  data() {
    return {
    };
  },
  components: {
    FullScreenModal,
  },
  props: {},
  beforeDestroy() {},
  async mounted() {},
  computed: {
    ...mapGetters(['isBuyFlowShow']),
  },
  watch: {

  },
  methods: {
    ...mapActions(['setBuyFlowShow']),
    closePolicy() {
      this.setBuyFlowShow(false);
    },
  }
}
</script>

<style scoped>
</style>
