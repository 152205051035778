<template>
  <div class="analyze-category-rank">
    <div class="item-nav">
      <h3 class="fs-h3">我擁有最多</h3>
      <div class="price d-flex justify-content-between">
        <span class="fs-h1" v-text="data[0].category1Name"></span>
        <span class="fs-h1">{{ data[0].count }} <span class="fs-14px">件</span></span>
      </div>
    </div>
    <div class="item-body">
      <div class="category-item" v-for="item in filteredData" :key="item.category1Name">
        <div class="fs-14px" v-text="item.category1Name"></div>
        <CustomHorizontalProgressBar :percentage="item.percentage" :count="item.count" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomHorizontalProgressBar from "@/components/CustomHorizontalProgressBar.vue";

export default {
  name: 'AnalyzeCategoryRank',
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomHorizontalProgressBar,
  },
  computed: {
    filteredData() {
      if (!this.data || this.data.length === 0) return [];
      const filtered = this.data.slice(1, 6);

      // 找出最高 count 值
      const maxCount = this.data[0].count;

      // 計算百分比並返回新陣列
      return filtered.map(item => {
        const percentage = ((item.count / maxCount) * 100).toFixed(0);
        return {
          ...item,
          percentage: Number(percentage), // 將百分比值新增到物件中
        };
      });
    }
	},
  watch: {
  },
  async mounted() {
	},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.analyze-category-rank {
  .item-body {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  .percentage-bar-group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .bar {
      height: 12px;
      border-radius: 6px;
      //transition: width 2s ease;
    }
    .desc {
      display: flex;
      justify-content: end;
      flex-basis: 40px;
      flex-shrink: 0;
      width: 40px;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.analyze-category-rank {

}
</style>
