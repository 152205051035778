<template>
  <div class="analyze-closet">
    <div class="analyze-nav">
      <div class="main-panel-data d-flex justify-content-between">
        <div class="item d-flex align-items-center flex-column">
          <div class="data">
            <span class="fs-h1" v-text="clothInTimeRangeLength"></span>
            <span class="fs-h4">件</span>
          </div>
          <div class="name fs-14px">單品</div>
        </div>
        <div class="item d-flex align-items-center flex-column">
          <div class="data">
            <span class="fs-h1">0</span>
            <span class="fs-h4">%</span>
          </div>
          <div class="name fs-14px">衣櫥使用率</div>
        </div>
        <div class="item d-flex align-items-center flex-column">
          <div class="data">
            <span class="fs-h1">0</span>
            <span class="fs-h4">次</span>
          </div>
          <div class="name fs-14px">平均衣物著次數</div>
        </div>
      </div>

      <div class="cloth-statistics-chart-group d-flex flex-column align-items-center">
        <b-button-group class="chart-group-nav" size="sm">
          <b-button
              :variant="activeClothStatistics === 'month' ? 'primary' : 'outline-primary'"
              @click="setActiveClothStatistics('month')"
          >
            每月
          </b-button>
          <b-button
              :variant="activeClothStatistics === 'year' ? 'primary' : 'outline-primary'"
              @click="setActiveClothStatistics('year')"
          >
            每年
          </b-button>
        </b-button-group>

        <D3MyClothStatistics class="chart-group-body" v-if="activeClothStatistics === 'month'" :data="myStatisticsData" />
        <D3MyClothYearStatistics class="chart-group-body" v-if="activeClothStatistics === 'year'" :data="myYearStatisticsData" />
      </div>

    </div>

    <div class="analyze-card-group">
      <div class="card-item">
        <div class="item-nav">
          <h3 class="fs-h3">衣櫥總價值</h3>
          <div class="price">
            <span class="fs-h1" v-text="clothTotalValueCommaString"></span>
            <span class="fs-h3 unit">元</span>
          </div>
        </div>

        <div class="item-body d-flex justify-content-between">
          <div class="percentage-chart-group" v-for="item in top3PriceSumCategories" :key="item.category1Name">
            <D3PercentageDonutChart :percentage="Number(item.priceSumPercent)" :size="72" :thickness="6" />
            <div class="fs-16px name">
              <span v-text="item.category1Name"></span>
              <span>(</span>
              <span v-text="item.count"></span>
              <span>件)</span>
            </div>
            <div class="fs-14px fw-bold price">
              <span v-text="'$' + priceToString(item.priceSum)"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="card-item">
        <div class="item-nav">
          <h3 class="fs-h3">單品平均價格</h3>
          <div class="price">
            <span class="fs-h1" v-text="clothAvgValueCommaString"></span>
            <span class="fs-h3 unit">元</span>
          </div>
        </div>

        <div class="item-body cloth-avg-value">
          <div class="fs-14px fw-bold">TOP 3 單品價格</div>
          <div class="top-3-cloth-group">
            <div class="cloth-item" v-for="item in top3ClothByPrice" :key="item.category1Name">
              <img :src="item.imgs[0]" :alt="item.category1Name">
              <div class="price fs-16px" v-text="'$' + priceToString(item.price)"></div>
            </div>
          </div>
          <CustomCollapse class="top-3-cloth-category-group" v-for="category in top3ClothCategoryByPriceSum" :key="category.category1">
            <template #toggle>
              <div class="cloth-category-item">
                <div class="title fs-14px fw-bold" v-text="category.category1Name + '（' + category.count + '）'"></div>
                <div class="number fs-14px" v-text="priceToString((category.priceSum / category.count).toFixed(0)) + '/件'"></div>
                <div class="option">
                  <Icon name="chevron-down" size="32" />
                </div>
              </div>
            </template>
            <template>
              <div class="cloth-item-group">
                <div class="cloth-item" v-for="item in category.parsed" :key="item.category2">
                  <div class="title fs-14px" v-text="item.category2Name + '（' + item.count + '）'"></div>
                  <div class="number fs-14px" v-text="'共 ' + item.priceSum + ' 元・' + priceToString((item.priceSum / item.count).toFixed(0)) + '/件'"></div>
                </div>
              </div>
            </template>
          </CustomCollapse>
        </div>
      </div>

      <AnalyzeColorRank :data="top4ClothColorRank" class="card-item" />

      <AnalyzeBrandRank :data="top5ClothBrandRank" class="card-item" />

      <AnalyzeCategoryRank :data="clothCategory1Rank" class="card-item" />

      <AnalyzeDurationRank class="card-item" />

      <div class="card-item">
        <div class="item-nav">
          <h3 class="fs-h3">增添的衣物</h3>
          <div class="price">
            <span class="fs-h1">0%</span>
            <span class="fs-h3 unit">是二手衣</span>
          </div>
        </div>

        <div class="item-body d-flex justify-content-center">
          <D3PercentageDistributionDonutChart :data="donutChartData" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CustomCollapse from "@/components/CustomCollapse.vue";
import AnalyzeColorRank from "@/views/test/AnalyzeColorRank.vue";
import AnalyzeBrandRank from "@/views/test/AnalyzeBrandRank.vue";
import AnalyzeCategoryRank from "@/views/test/AnalyzeCategoryRank.vue";
import AnalyzeDurationRank from "@/views/test/AnalyzeDurationRank.vue";
import D3MyClothStatistics from "@/views/test/D3MyClothStatistics.vue";
import D3MyClothYearStatistics from "@/views/test/D3MyClothYearStatistics.vue";
import D3PercentageDonutChart from "@/views/test/D3PercentageDonutChart.vue";
import D3PercentageDistributionDonutChart from "@/views/test/D3PercentageDistributionDonutChart.vue";

export default {
  name: 'AnalyzeCloset',
  data() {
    return {
      activeClothStatistics: 'month',
      myStatisticsData: [
        { all: 109, new: 10, out: 5, month: 10, year: 2023 },
        { all: 111, new: 2, out: 0, month: 11, year: 2023 },
        { all: 109, new: 1, out: 3, month: 12, year: 2023 },
        { all: 110, new: 1, out: 0, month: 1, year: 2024 },
        { all: 112, new: 3, out: 1, month: 2, year: 2024 },
        { all: 111, new: 1, out: 2, month: 3, year: 2024 },
        { all: 106, new: 0, out: 5, month: 4, year: 2024 },
        { all: 106, new: 1, out: 1, month: 5, year: 2024 },
        { all: 108, new: 2, out: 0, month: 6, year: 2024 },
        { all: 113, new: 7, out: 2, month: 7, year: 2024 },
        { all: 114, new: 2, out: 1, month: 8, year: 2024 },
        { all: 110, new: 3, out: 7, month: 9, year: 2024 },
      ],
      myYearStatisticsData: [
        { new: 35, out: 23, year: 2022 },
        { new: 31, out: 29, year: 2023 },
        { new: 21, out: 17, year: 2024 },
        { new: 21, out: 17, year: 2025 },
        { new: 21, out: 17, year: 2026 },
        { new: 21, out: 17, year: 2027 },
      ],
      chartData: [
        { month: 'a', value: 109 },
        { month: 'b', value: 111 },
        { month: 'c', value: 109 },
        { month: 'd', value: 110 },
        { month: 'e', value: 112 },
        { month: '6', value: 111 },
        { month: '7', value: 106 },
        { month: '8', value: 106 },
        { month: '9', value: 108 },
        { month: '10', value: 113 },
        { month: '11', value: 114 },
        { month: '12', value: 110 },
      ],
      donutChartData: [
        { name: '二手衣', percentage: 65 },
        { name: '新衣', percentage: 20 },
        { name: '無數據', percentage: 15 },
      ],
    };
  },
  components: {
    CustomCollapse,
    AnalyzeColorRank,
    AnalyzeBrandRank,
    AnalyzeCategoryRank,
    AnalyzeDurationRank,
    D3MyClothStatistics,
    D3MyClothYearStatistics,
    D3PercentageDonutChart,
    D3PercentageDistributionDonutChart,
  },
  computed: {
    ...mapState(['user', 'cloths', 'clothColors', 'clothCategories']),

    clothInTimeRange() {
      const filterdCloths = this.cloths.filter((cloth) => {
        return cloth.recycleType === -1 && !cloth.isDeleted;
      });
      return filterdCloths;
    },
    clothInTimeRangeLength() {
      return this.clothInTimeRange.length;
    },
    clothTotalValue() {
      const value = this.clothInTimeRange.reduce((sum, cloth) => {
        return cloth.price > 0? sum + cloth.price: sum;
      }, 0);
      return value;
    },
    clothTotalValueCommaString() {
      return this.clothTotalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clothAvgValueCommaString() {
      return (this.clothTotalValue / this.clothInTimeRange.length).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clothCategory1Rank() {
      const clothCategory1Rank = this.clothInTimeRange.reduce((ary, cloth) => {
        const {category1} = cloth;
        for (const a of ary) {
          if (a.category1 === category1) {
            a.count += 1;
            a.priceSum += cloth.price > 0 ? cloth.price : 0;
            a.sub.push(cloth);
            return ary;
          }
        }
        const category1Name = this.__findCategory1Name(category1);
        ary.push({
          category1,
          category1Name,
          sub: [cloth],
          count: 1,
          priceSum: cloth.price > 0 ? cloth.price : 0,
        });
        return ary;
      }, []);

      for (const c of clothCategory1Rank) {
        c.parsed = this.__subParse(c.sub);
      }
      return clothCategory1Rank.sort((a, b) => { return b.count - a.count });
    },
    top3PriceSumCategories() {
      const totalValue = this.clothTotalValue;
      return [...this.clothCategory1Rank] // 使用展開運算符複製陣列
          .sort((a, b) => b.priceSum - a.priceSum)
          .slice(0, 3)
          .map(item => ({
            category1Name: item.category1Name,
            count: item.count,
            priceSum: item.priceSum,
            priceSumPercent: totalValue
                ? ((item.priceSum / totalValue) * 100).toFixed(0)
                : 0, // 計算百分比
          }));
    },
    top3ClothByPrice() {
      return this.clothInTimeRange
          .slice() // 建立副本
          .sort((a, b) => b.price - a.price) // 按價格降序排列
          .slice(0, 3) // 取前3個物件
          .map(item => ({
            price: item.price,
            imgs: item.imgs,
            category1Name: this.__findCategory1Name(item.category1)
          }));
    },
    top3ClothCategoryByPriceSum() {
      return this.clothCategory1Rank
          .slice() // 複製原陣列以避免直接修改原陣列
          .sort((a, b) => b.priceSum - a.priceSum) // 根據 priceSum 降序排列
          .slice(0, 3); // 取前3個物件
    },
    top4ClothColorRank() {
      const colorMap = new Map();

      this.clothInTimeRange.forEach(cloth => {
        const colorId = cloth.color;
        const color = this.clothColors.find(c => c.id === colorId);

        if (colorMap.has(colorId)) {
          colorMap.get(colorId).count += 1;
        } else {
          colorMap.set(colorId, {
            colorId: colorId,
            count: 1,
            color: color,
          });
        }
      });

      // 將 Map 轉換為陣列並根據 count 進行排序，取前 4 名
      const sortColorRank = [...colorMap.values()]
          .sort((a, b) => b.count - a.count)
          .slice(0, 4);

      return sortColorRank;
    },
    top5ClothBrandRank() {
      const brandCountMap = new Map();

      // 使用 Map 儲存品牌和其計數
      this.clothInTimeRange.forEach((cloth) => {
        cloth.brands.forEach((brand) => {
          brandCountMap.set(brand, (brandCountMap.get(brand) || 0) + 1);
        });
      });

      // 將 Map 轉為陣列並排序
      const sortedBrandRank = [...brandCountMap.entries()]
          .map(([brand, count]) => ({ brand, count })) // 格式化為對象
          .sort((a, b) => b.count - a.count); // 按 count 排序

      // 返回前 5 名
      return sortedBrandRank.slice(0, 5);
    },
	},
  watch: {
    
  },
  async mounted() {
    if(this.user.serial !== 516) {
      await this.$router.push({ name: 'Home' });
    }
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'showMsgModal', 'updateCloth']),

    setActiveClothStatistics(type) {
      this.activeClothStatistics = type;
    },
    __subParse(cloths) {
      const res = [];
      for (const c of cloths) {
        const {category2} = c;
        let found = false;
        for (let i=0;i<res.length;i++) {
          if (res[i].category2 === category2) {
            found = true;
            res[i].count += 1;
            res[i].priceSum += c.price > 0 ? c.price : 0;
            break;
          }
        }
        if (!found) {
          const category2Name = this.__findCategory2Name(category2);
          res.push({
            category2,
            category2Name,
            count: 1,
            priceSum: c.price > 0 ? c.price : 0,
          });
        }
      }
      return res.sort((a, b) => { return b.count - a.count });
    },
    __findCategory1Name(category1) {
      for (const c of this.clothCategories) {
        if (c.serial === category1) {
          return c.name;
        }
      }
      return '';
    },
    __findCategory2Name(category2) {
      for (const c of this.clothCategories) {
        for (const c2 of c.sub) {
          if (c2.serial === category2) {
            return c2.name;
          }
        }
      }
      return '';
    },
    priceToString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.analyze-closet {
  background: $color-gray-light;
  padding: 0 0 2.5rem;
  .analyze-nav {
    background: $color-white;
    padding: 1rem;
    .main-panel-data {
      padding: .5rem;
      .item {
        width: 100px;
      }
      .data .fs-h4 {
        margin: 0 0 0 .5rem;
      }
      .name {
        color: $typography-secondary-default;
      }
    }
  }
  .cloth-statistics-chart-group {
    .chart-group-nav, .chart-group-body {
      padding: 1rem 0;
    }
    .chart-group-nav {
      width: 75%;
    }
  }
  .analyze-card-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    .card-item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: $color-white;
      border-radius: 1rem;
      padding: 1rem;
      .item-nav {
        h3.fs-h3 {
          margin: 0;
        }
        .price .unit {
          margin-left: .5rem;
        }
      }
      .item-body {
        &.cloth-avg-value {
          display: flex;
          flex-direction: column;
          gap: .5rem;
        }
      }
    }
  }
  .percentage-chart-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    .name {
      margin-top: .5rem;
    }
    .price {
      margin-top: .125rem;
    }
  }
  .top-3-cloth-group {
    display: flex;
    gap: .5rem;
    .cloth-item {
      position: relative;
      width: calc((100% - 1rem)/3);
      padding-top: calc((100% - 1rem)/3);
      border-radius: .625rem;
      overflow: hidden;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,.02), rgba(0,0,0,.1));
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .price {
        position: absolute;
        bottom: .25rem;
        left: 50%;
        color: $color-black-300;
        mix-blend-mode: difference; /* 根據背景亮暗自適應 */
        pointer-events: none; /* 確保文字層不阻擋圖片互動 */
        transform: translateX(-50%);
      }
    }
  }
  .top-3-cloth-category-group {
    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
    .cloth-category-item {
      display: flex;
      align-items: center;
      padding: .5rem;
      .title {
        flex: 4;
      }
      .number {
        display: flex;
        justify-content: end;
        flex: 3;
      }
      .option {
        display: flex;
        justify-content: end;
        flex: 3;
      }
    }
    .cloth-item-group {
      padding: 0 .5rem .5rem;
    }
    .cloth-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.analyze-closet {
  .btn-group-sm > .btn {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: .5rem;
    padding: .25rem 1rem;
  }
  .btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .collapse-toggle.active .cloth-category-item .option svg {
    transform: rotate(180deg);
  }
}
</style>
