<template>
  <div class="d3-percentage-distribution-donut-chart">
    <svg ref="chart" :width="size" :height="size"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";

export default {
  name: 'D3PercentageDistributionDonutChart',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      isVisible: false,
      pieData: null,
      arc: null,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [
        { name: '二手衣', percentage: 65 },
        { name: '新衣', percentage: 20 },
        { name: '無數據', percentage: 15 },
      ]
    },
    size: {
      type: Number,
      default: 200
    },
    strokeWidth: {
      type: Number,
      default: 20
    },
    duration: {
      type: Number,
      default: 2000,
    },
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.drawChart();
    this.useObserver(
        this.$el,
        () => {
          this.isVisible = true;
          this.animateChart();
        }
    );
  },
  methods: {
    drawChart() {
      const { data, size, strokeWidth } = this;

      // 計算圖表大小和半徑
      const width = size;
      const height = size;
      const radius = Math.min(width, height) / 2;

      // 設置顏色比例尺
      const colorScale = d3.scaleOrdinal().range(["#14983D", "#E7B780", "#D07000"]);

      // 設置弧度，增加 cornerRadius
      const arc = d3.arc()
          .innerRadius(radius - strokeWidth)
          .outerRadius(radius)
          .cornerRadius(strokeWidth/2); // 圓角半徑

      // 設置圓餅圖的數據
      const pie = d3.pie()
          .value(d => d.percentage)
          .sort(null)
          .startAngle(0)
          .endAngle(-2 * Math.PI)
          .padAngle(0.03);

      // 創建SVG容器
      const svg = d3.select(this.$refs.chart)
          .append("svg")
          .attr("width", width)
          .attr("height", height)
          .append("g")
          .attr("transform", `translate(${width / 2}, ${height / 2})`);

      // 設置圓餅圖的數據
      this.pieData = pie(data);

      // 繪製每個圓餅切片，並加入動畫
      const slices = svg.selectAll(".slice")
          .data(this.pieData)
          .enter().append("g")
          .attr("class", "slice");

      slices.append("path")
          .attr("fill", (d, i) => colorScale(i))
          .attr("d", d => {
            const startAngle = { startAngle: d.startAngle, endAngle: d.startAngle }; // 初始角度
            return arc(startAngle);
          });

      // 繪製文字，並同步加入透明度動畫
      slices.append("text")
          .attr("transform", d => {
            const centroid = arc.centroid(d);
            return `translate(${centroid[0] * 0.95},${centroid[1] * 0.95})`; // 向內偏移
          })
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .attr("font-size", "14px")
          .attr("font-weight", "bold")
          .attr("dy", "-9")
          .style("opacity", 0) // 初始透明度
          .text(d => `${d.data.percentage}%`);

      slices.append("text")
          .attr("transform", d => {
            const centroid = arc.centroid(d);
            return `translate(${centroid[0] * 0.95},${centroid[1] * 0.95})`; // 向內偏移
          })
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .attr("font-size", "14px")
          .attr("font-weight", "bold")
          .attr("dy", "9")
          .style("opacity", 0) // 初始透明度
          .text(d => d.data.name);
    },
    animateChart() {
      const { size, strokeWidth, duration } = this;

      // 計算圖表大小和半徑
      const width = size;
      const height = size;
      const radius = Math.min(width, height) / 3.3;

      // 設置弧度，增加 cornerRadius
      const arc = d3.arc()
          .innerRadius(radius - strokeWidth)
          .outerRadius(radius)
          .cornerRadius(strokeWidth/2); // 圓角半徑

      const svg = d3.select(this.$refs.chart).select("g");

      // 繪製動畫
      svg.selectAll(".slice path")
          .data(this.pieData)
          .transition() // 加入動畫
          .duration(duration) // 動畫持續時間
          .attrTween("d", function(d) {
            const interpolate = d3.interpolate(
                { startAngle: d.startAngle, endAngle: d.startAngle }, // 初始狀態
                d // 最終狀態
            );
            return function(t) {
              return arc(interpolate(t)); // 漸進更新
            };
          });

      // 動畫期間逐漸顯示文字
      svg.selectAll(".slice text")
          .transition() // 與圓環動畫同步
          .duration(duration) // 動畫持續時間
          .style("opacity", 1);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.d3-percentage-distribution-donut-chart {

}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.d3-percentage-distribution-donut-chart {

}
</style>
  