<template>
  <div class="d3-my-cloth-statistics" :style="{ width: width + 'px' }">
    <div class="chart">
      <div class="y-axis">
        <svg ref="chart-y-axis"></svg>
      </div>
      <div class="chart-body"
           :style="{ maxWidth: chartWidth + 'px' }"
           @pointerdown="startDrag"
           @pointermove="drag"
           @pointerup="stopDrag"
           @pointerleave="stopDrag"
           ref="chartBody">
        <svg ref="chart"></svg>
      </div>
      <div class="y-axis">
        <svg ref="chart-y-axis-right"></svg>
      </div>
    </div>
    <div class="chart-mark-list">
      <div class="chart-mark-item">
        <div class="chart-mark-dot add"></div>
        <span class="fs-12px fw-bold">新增</span>
      </div>
      <div class="chart-mark-item">
        <div class="chart-mark-dot out"></div>
        <span class="fs-12px fw-bold">淘汰</span>
      </div>
      <div class="chart-mark-item">
        <div class="chart-mark-dot all"></div>
        <span class="fs-12px fw-bold">總量</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";

export default {
  name: 'D3MyClothStatistics',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    chartWidth: {
      type: Number,
      default: 378,
    },
    width: {
      type: Number,
      default: 330,
    },
    height: {
      type: Number,
      default: 200,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    margin: {
      type: Object,
      default: () => ({ top: 16, right: 36, bottom: 36, left: 36 }),
    },
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.drawChart(false);
    this.useObserver(
        this.$el,
        () => {
          this.drawChart(true);
        }
    );
  },
  methods: {
    drawChart(animate) {
      const { data, chartWidth, width, height, duration, margin } = this;
      const validData = data.map((d, index) => ({
        ...d,
        month: String(d.month), // X軸標線項目為數字時, 在跑折線動畫可能會出現無法正確定位的NaN bug, 所以必須確保 month 是字符串
        index: index + '',
      }));

      const chartHeight = height - 50 - margin.top - margin.bottom;

      // 繪製主圖表
      const chartContainer = d3.select(this.$refs.chart);
      chartContainer.selectAll("*").remove(); // 清空容器

      const svg = chartContainer
          .attr("width", chartWidth)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(0, ${margin.top})`);

      // 定義縮放比例
      const x0 = d3
          .scaleBand()
          .domain(validData.map((d) => d.index))
          .range([10, chartWidth - 10])
          .padding(0.2);

      const x1 = d3
          .scaleBand()
          .domain(["new", "out"])
          .range([0, x0.bandwidth()])
          .padding(0.05);

      const y = d3
          .scaleLinear()
          .domain([0, d3.max(validData, (d) => Math.max(d.new, d.out, d.all) * 1.2)]) // Include 'all' for the max y-axis range
          .range([chartHeight, 0])
          .nice(5);

      // 定義顏色
      const color = d3.scaleOrdinal().domain(["new", "out"]).range(["#F7D88F", "#B1D8BD"]);

      // 繪製柱狀圖
      const bars = svg
          .selectAll(".group")
          .data(validData)
          .join("g")
          .attr("transform", (d) => {
            return `translate(${x0(d.index)},0)`;
          })
          .selectAll("rect")
          .data((d) => ["new", "out"].map((key) => ({ key, value: d[key] })))
          .join("rect")
          .attr("x", (d) => x1(d.key))
          .attr("width", x1.bandwidth())
          .attr("fill", (d) => color(d.key));

      if (animate) {
        bars
            .attr("y", chartHeight)
            .attr("height", 0)
            .transition()
            .duration(duration)
            .attr("y", (d) => y(d.value))
            .attr("height", (d) => chartHeight - y(d.value));
      } else {
        bars.attr("y", () => y(0)).attr("height", 0); // 初始靜止
      }

      // 創建漸層
      const area = d3
          .area()
          .x((d) => x0(d.index) + x0.bandwidth() / 2)
          .y0(chartHeight)
          .y1((d) => y(d.all));

      const gradient = svg
          .append("defs")
          .append("linearGradient")
          .attr("id", "gradientArea")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");

      gradient
          .append("stop")
          .attr("offset", "0%")
          .attr("stop-color", "#E96D45")
          .attr("stop-opacity", .15);

      gradient
          .append("stop")
          .attr("offset", "50%")
          .attr("stop-color", "#E96D45")
          .attr("stop-opacity", 0);

      // 渲染漸層區域
      svg
          .append("path")
          .datum(validData)
          .attr("fill", "url(#gradientArea)")
          .attr("d", area);

      if (animate) {
        svg
            .selectAll("path")
            .transition()
            .duration(duration)
            .attrTween("d", function () {
              const interpolate = d3.interpolateArray(
                  validData.map((d) => ({ month: d.month, all: 0 })),
                  validData
              );
              return (t) => area(interpolate(t));
            });
      }

      // 繪製折線圖
      const line = d3
          .line()
          .x((d) => x0(d.index) + x0.bandwidth() / 2)
          .y(() => chartHeight); // 初始位置為圖表底部

      const finalLine = d3
          .line()
          .x((d) => x0(d.index) + x0.bandwidth() / 2)
          .y((d) => y(d.all)); // 最終值

      const path = svg
          .append("path")
          .datum(validData)
          .attr("fill", "none")
          .attr("stroke", "#E96D45")
          .attr("stroke-width", 1)
          .attr("d", line);

      if (animate) {
        path
            .transition()
            .duration(duration)
            .attrTween("d", function () {
              const interpolate = d3.interpolateArray(
                  validData.map((d) => ({ month: d.month, all: 0 })),
                  validData
              );
              return (t) => finalLine(interpolate(t));
            });
      }

      // 繪製可操作點
      const dots = svg
          .selectAll(".dot")
          .data(validData)
          .join("circle")
          .attr("cx", (d) => x0(d.index) + x0.bandwidth() / 2)
          .attr("cy", chartHeight) // 初始位置
          .attr("r", 4)
          .attr("fill", "white")
          .attr("stroke", "#E96D45")
          .attr("stroke-width", 1);

      if (animate) {
        dots
            .transition()
            .duration(duration)
            .attr("cy", (d) => y(d.all))
            .attr("class", "dot").end()
            .then(() => {
              // 添加交互
              const circles = svg.selectAll(".dot");

              // 設置預設選中最後一個點
              const lastCircle = circles.filter((_, i) => i === validData.length - 1);
              lastCircle.attr("fill", "#E96D45").attr("data-selected", "true");

              // 顯示最後一個點的數值
              const textGroup = svg.append("g").attr("class", "value-group"); // 創建一個分組，包含背景矩形和文字
              // 設置文字內容
              const text = textGroup
                  .append("text")
                  .attr("class", "value-text")
                  .attr("x", +lastCircle.attr("cx"))
                  .attr("y", +lastCircle.attr("cy") - 20)
                  .attr("text-anchor", "middle")
                  .attr("fill", "white") // 字體顏色設置為白色
                  .attr("font-size", "12px") // 字體大小
                  .attr("font-weight", "600") // 字體粗細
                  .text(validData[validData.length - 1].all + ' 件');
              // 獲取文字的寬度與高度
              const bbox = text.node().getBBox();
              // 創建背景矩形
              textGroup
                  .insert("rect", "text") // 在文字之前插入矩形
                  .attr("x", bbox.x - 8) // 矩形的 X 起點，留出 padding 的空間
                  .attr("y", bbox.y - 5) // 矩形的 Y 起點，留出 padding 的空間
                  .attr("width", bbox.width + 16) // 矩形寬度，增加 padding
                  .attr("height", bbox.height + 10) // 矩形高度，增加 padding
                  .attr("fill", "#E96D45") // 背景顏色
                  .attr("rx", 8) // 設置圓角半徑，實現圓角矩形
                  .attr("ry", 8); // 設置圓角半徑，實現圓角矩形

              // 點擊功能
              circles.on("click", function (event, d) {
                const circle = d3.select(this);
                const isSelected = circle.attr("data-selected") === "true";

                // 清空所有圓點的狀態
                svg.selectAll(".dot").attr("fill", "white").attr("data-selected", "false");
                svg.selectAll(".value-group").remove();

                if (!isSelected) {
                  // 將當前點設為選中
                  circle.attr("fill", "#E96D45").attr("data-selected", "true");

                  // 添加數字標籤
                  const textGroup = svg.append("g").attr("class", "value-group"); // 創建一個分組，包含背景矩形和文字
                  // 設置文字內容
                  const text = textGroup
                      .append("text")
                      .attr("class", "value-text")
                      .attr("x", +circle.attr("cx"))
                      .attr("y", +circle.attr("cy") - 20)
                      .attr("text-anchor", "middle")
                      .attr("fill", "white") // 字體顏色設置為白色
                      .attr("font-size", "12px") // 字體大小
                      .attr("font-weight", "600") // 字體粗細
                      .text(d.all + ' 件');
                  // 獲取文字的寬度與高度
                  const bbox = text.node().getBBox();
                  // 創建背景矩形
                  textGroup
                      .insert("rect", "text") // 在文字之前插入矩形
                      .attr("x", bbox.x - 8) // 矩形的 X 起點，留出 padding 的空間
                      .attr("y", bbox.y - 5) // 矩形的 Y 起點，留出 padding 的空間
                      .attr("width", bbox.width + 16) // 矩形寬度，增加 padding
                      .attr("height", bbox.height + 10) // 矩形高度，增加 padding
                      .attr("fill", "#E96D45") // 背景顏色
                      .attr("rx", 8) // 設置圓角半徑，實現圓角矩形
                      .attr("ry", 8); // 設置圓角半徑，實現圓角矩形
                }
              });
            });
      }

      // 繪製X軸
      const xAxisGroup = svg
          .append("g")
          .attr("transform", `translate(0,${chartHeight})`);

      // 定義 X 軸並繪製
      const xAxis = d3.axisBottom(x0).tickFormat(() => ""); // 暫時不設置文字
      xAxisGroup.call(xAxis);

      // 繪製橫跨整個圖表的X軸線
      xAxisGroup
          .append("line")
          .attr("x1", 0)  // X 軸起點
          .attr("x2", chartWidth)  // X 軸終點
          .attr("y1", 0)  // 線的Y起點
          .attr("y2", 0)  // 線的Y終點
          .attr("stroke", "#C6C6C6")  // 設置顏色
          .attr("stroke-width", 1);  // 設置線寬

      // 自定義月份與年份標籤
      xAxisGroup.selectAll(".tick").each(function (d, i) {
        const currentData = validData[i];
        const prevData = validData[i - 1];

        // 當前的 tick 元素
        const tick = d3.select(this);

        // 添加月份
        const textElement = tick
            .append("text")
            .attr("text-anchor", "middle")
            .attr("fill", "#6F6F6F")
            .style("font-size", "12px")
            .style("font-weight", "bold");

        textElement
            .append("tspan")
            .text(`${currentData.month}月`) // 顯示月份
            .attr("x", 0)
            .attr("dy", "1.1em"); // 確保垂直居中

        // 如果是每年的第一個月份，添加年份
        if (!prevData || currentData.year !== prevData.year) {
          textElement
              .append("tspan")
              .text(`${currentData.year}`) // 顯示年份
              .attr("x", 0)
              .attr("dy", "1.2em"); // 添加年份時向下偏移
        }
      });

      // 隱藏標線
      xAxisGroup.selectAll(".tick line").style("display", "none");
      xAxisGroup.selectAll("path").style("display", "none");

      // 修改文字樣式
      xAxisGroup.selectAll("text")
          .style("font-size", "12px")
          .style("line-height", "14px")
          .style("font-weight", "bold")
          .style("fill", "#6F6F6F");


      // 繪製 Y 軸，保持固定
      const yAxisSvg = d3.select(this.$refs["chart-y-axis"]);
      const yAxisSvgRight = d3.select(this.$refs["chart-y-axis-right"]);
      yAxisSvg.selectAll("*").remove();
      yAxisSvgRight.selectAll("*").remove(); // 清空Y軸容器

      // 在左側 Y 軸繪製
      const yAxisGroupLeft = yAxisSvg
          .attr("width", margin.left)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left - 1}, ${margin.top})`);

      // 定義左側 Y 軸
      const yAxis = d3.axisLeft(y)
          .tickFormat((d) => d === 0 ? "" : `${d}件`)  // 隱藏 0 的文字
          .ticks(5);

      yAxisGroupLeft.call(yAxis);

      // 隱藏標線和主軸線
      yAxisGroupLeft.selectAll("line").style("display", "none"); // 隱藏標線
      yAxisGroupLeft.selectAll("path").style("display", "none"); // 隱藏主軸線

      // 修改文字樣式
      yAxisGroupLeft.selectAll("text")
          .style("font-size", "12px")
          .style("fill", "#6F6F6F")
          .attr("dx", "0.4em"); // 設置字體樣式

      // 在右側 Y 軸繪製
      const yAxisGroupRight = yAxisSvgRight
          .attr("width", margin.right)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(14, ${margin.top})`); // 右側 Y 軸，稍微偏移

      yAxisGroupRight.call(yAxis);

      // 隱藏標線和主軸線
      yAxisGroupRight.selectAll("line").style("display", "none"); // 隱藏標線
      yAxisGroupRight.selectAll("path").style("display", "none"); // 隱藏主軸線

      // 修改右側 Y 軸的文字樣式
      yAxisGroupRight.selectAll("text")
          .style("font-size", "12px")
          .style("fill", "#6F6F6F")
          .style("text-anchor", "start"); // 讓文字靠左對齊

      if((width - margin.left - margin.right) < chartWidth && animate) {
        const chartElement = this.$refs.chartBody;
        const targetScrollLeft = chartElement.scrollWidth - chartElement.clientWidth;
        this.smoothScroll(chartElement, targetScrollLeft, (duration - 500));
      }
    },
    smoothScroll(element, target, duration) {
      const start = element.scrollLeft;
      const distance = target - start;
      const startTime = performance.now();

      function step(currentTime) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // 確保 progress 不超過 1
        element.scrollLeft = start + distance * progress;

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      }

      requestAnimationFrame(step);
    },
    // 開始拖曳
    startDrag(e) {
      this.isDown = true;
      this.startX = e.pageX - this.$refs.chartBody.offsetLeft;
      this.scrollLeft = this.$refs.chartBody.scrollLeft;
    },
    // 拖曳過程中
    drag(e) {
      if (!this.isDown) return; // 如果不是按下狀態，不處理
      e.preventDefault();
      const x = e.pageX - this.$refs.chartBody.offsetLeft;
      const walk = (x - this.startX) * 2; // 調整拖曳速度
      this.$refs.chartBody.scrollLeft = this.scrollLeft - walk;
    },
    // 停止拖曳
    stopDrag() {
      this.isDown = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.d3-my-cloth-statistics {
  .chart {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    .y-axis {
      width: 36px;
      position: relative;
      flex-shrink: 0; /* 防止壓縮 */
    }
    .chart-body {
      pointer-events: auto;
      overflow-x: auto; /* 滾動條 */
      -ms-overflow-style: none; /* 適用於 IE/Edge */
      scrollbar-width: none; /* 適用於 Firefox */
      &::-webkit-scrollbar {
        display: none; /* 適用於 Chrome/Safari */
      }
    }
  }
  .chart-mark-list {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    .chart-mark-item {
      display: flex;
      align-items: center;
      gap: .5rem;
      .chart-mark-dot {
        width: .5rem;
        height: .5rem;
        background: $color-black;
        border-radius: 100%;
        &.add {
          background: #F7D88F;
        }
        &.out {
          background: #B1D8BD;
        }
        &.all {
          background: #E96D45;
        }
      }
      span {
        color: $typography-secondary-default;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.d3-my-cloth-statistics {

}
</style>
  