<template>
  <div class="order-sell-cloth-item">
    <div class="flex-content">
      <div>
        <div class="item-image">
          <ThumbnailSlider :img-src-array="sellCloth.imgs" :img-alt="'product-image'" />
        </div>
        <div class="item-serial" v-text="formatSerial(sellCloth)"></div>
      </div>

      <div class="item-info origin" v-if="orderStatus === 1">
        <div class="name-with-brand">
          <p class="info-brand-name"><small v-text="formattedBrandName"></small></p>
          <p class="info-item-name" :class="{'unfilled': !Boolean(category1 && category2)}" v-text="formattedCardName"></p>
        </div>
        <div class="info-text fs-14px d-flex align-items-center" :class="{'unfilled': priceAndSizeUnfilled}">
          <span>購入價 $</span>
          <span v-text="formattedClothBoughtPrice"></span>
        </div>
        <div class="info-text fs-14px d-flex align-items-center" :class="{'unfilled': priceAndSizeUnfilled}">
          <span>尺寸&nbsp;</span>
          <span>{{ sellCloth.size ? sellCloth.size : '未填' }}</span>
        </div>
        <div class="desc fs-14px" v-text="condition"></div>
      </div>

      <div class="item-info" v-if="orderStatus > 1">
        <div class="d-flex justify-content-between mb-3">
          <div class="name-with-brand">
            <p class="info-brand-name"><small v-text="formattedBrandName"></small></p>
            <p class="info-item-name" v-text="formattedCardName"></p>
          </div>

          <div></div>
          <div class="status-state">
            <span class="status-b status-negative" v-if="orderStatus < 6 && sellCloth.confirmStatus === 1 && sellCloth.passStatus !== 2">同意</span>
            <span class="status-b status-negative" v-if="orderStatus > 3 && sellCloth.confirmStatus === 2">不同意</span>
            <span class="status-b status-negative" v-if="sellCloth.passStatus === 2">店家拒絕</span>
            <span class="status-b status-positive" v-if="orderStatus === 6">等待收件</span>
            <span class="status-b status-negative" v-if="orderStatus >= 6 && sellCloth.transferStatus === 2">未收到</span>
            <span class="status-b status-stock" v-if="sellCloth.returnPriceType === 0 && (sellCloth.stockStatus === 0 || sellCloth.stockStatus === 1)">已收件</span>
            <span class="status-b status-sold" v-if="sellCloth.stockStatus === 5">售出</span>
            <span class="status-b status-cancel" v-if="sellCloth.returnPriceType === 1 || sellCloth.stockStatus === 4">捐贈</span>
            <span class="status-b status-negative" v-if="sellCloth.stockStatus === 2">退回</span>
          </div>
        </div>

        <div class="price-info" v-if="sellCloth.passStatus !== 2">
          <div class="d-flex justify-content-between">
            <div>
              <p class="mb-0">購入價</p>
            </div>
            <div>
              <p class="mb-0">{{ formattedClothBoughtPrice }}</p>
            </div>
          </div>
          
          <div v-if="sellCloth.stockStatus === 0">
            <div class="d-flex justify-content-between" v-if="eventObj.priceDisplayType === 0">
              <div>
                <p class="mb-0">預期回饋金</p>
              </div>
              <div>
                <p class="mb-0">${{ parseInt(sellCloth.predictPriceMin * sellCloth.returnRatePercentage / 100) }} - ${{ parseInt(sellCloth.predictPriceMax * sellCloth.returnRatePercentage / 100) }}</p>
              </div>
            </div>
  
            <div class="d-flex justify-content-between" v-if="eventObj.priceDisplayType === 1">
              <div>
                <p class="mb-0">預期售價</p>
              </div>
              <div>
                <p class="mb-0">${{ sellCloth.predictPriceMin }} - ${{ sellCloth.predictPriceMax }}</p>
              </div>
            </div>

            <div class="warning-text fs-16px d-flex justify-content-between" v-if="sellCloth.needBargain">
              <div>議價金額</div>
              <div v-text="formattedBargainPrice"></div>
            </div>

          </div>

          <div v-if="sellCloth.stockStatus === 1 || sellCloth.stockStatus === 5">
            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-0">回饋金</p>
              </div>
              <div>
                <p class="mb-0">${{ sellCloth.soldReturn }}</p>
              </div>
            </div>

          </div>

        </div>

        <div v-if="sellCloth.passStatus === 2">
          <span>原因：</span>
          <span v-text="sellCloth.failedReason"></span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ThumbnailSlider from "@/components/ThumbnailSlider.vue";

export default {
  name: 'OrderSellClothItem',
  data() {
    return {
    };
  },
  props: {
    sellCloth: {
      type: Object,
      require: true,
    },
    eventObj: {
      type: Object,
      require: true,
    },
    orderStatus: {
      type: Number,
      required: true,
    },
  },
  components: {
    ThumbnailSlider,
  },
  computed: {
    ...mapState(['clothCategories']),
    formattedClothBoughtPrice() {
      if (this.sellCloth.boughtPriceUnknown) {
        return 'N/A';
      }
      return this.sellCloth.boughtPrice;
    },
    formattedBrandName() {
      return this.sellCloth.brand ? this.sellCloth.brand : '品牌名';
    },
    category1() {
      const res = this.clothCategories.filter((item) => {
        return item.serial === this.sellCloth.category1;
      });
      if (res.length > 0) {
        return res[0];
      }
      return null;
    },
    category2() {
      for (const c1 of this.clothCategories) {
        for (const c2 of c1.sub) {
          if (c2.serial === this.sellCloth.category2) {
            return c2;
          }
        }
      }
      return null;
    },
    formattedCardName() {
      return this.category1 && this.category2 ? this.category1.name + '/' + this.category2.name : '單品類別'
    },
    priceAndSizeUnfilled() {
      return this.sellCloth.price === 0 || this.sellCloth.price === null || this.sellCloth.size === null || this.sellCloth.size === '';
    },
    condition() {
      return this.sellCloth.erosionRate ? this.sellCloth.erosionRate : '新舊程度'
    },
    formattedBargainPrice() {
      return this.sellCloth.bargainPrice ? '$' + this.sellCloth.bargainPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    formatSerial(cloth) {
      if (cloth.sellId) {
        return cloth.sellId;
      }
      return cloth.serial.toString().padStart(5, '0');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.order-sell-cloth-item {
  padding: 1rem;
  .flex-content {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    .item-image {
      flex-shrink: 0;
      width: 120px;
      height: 120px;
    }
    .item-serial {
      color: $color-btn-disabled-secondary;
      font-size: $font-extra-small;
      line-height: 1rem;
      margin-top: .25rem;
    }

    .item-info {
      flex-shrink: 0;
      flex-basis: calc(100% - 120px - 16px);
      max-width: calc(100% - 120px - 16px);
      //font-size: .8rem;
      &.origin {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
      }

      .name-with-brand {
        //flex: 1 1 60%;
        max-width: calc(100% - 56px);
        p {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      //.status-state {
      //  flex: 0 0 auto;
      //}

      .info-brand-name {
        color: $typography-secondary-default;
        line-height: 16px;
        margin-bottom: 0;
      }

      .info-item-name, .info-text {
        color: $typography-primary-default;
        margin-bottom: 0;
        &.unfilled {
          color: $typography-secondary-default;
        }
      }

      .status-b {
        color: $typography-primary-default;
        //border: solid 1px #E7B780;
        padding: 4px 8px;
        border-radius: 1.5rem;
        font-size: $font-medium;
        font-weight: $font-weight-semi-bold;
        &.status-positive {
          background-color: $color-yellow-light;
        }
        &.status-negative {
          background-color: $color-gray-light;
        }
        &.status-stock, &.status-sold {
          color: $color-white;
          background-color: $typography-interactive-primary;
          padding: 4px 12px;
        }
        &.status-cancel {
          color: $color-white;
          background-color: $typography-secondary-default;
        }
      }

      .price-info {
        //font-size: .8rem;
        .warning-text {
          color: $typography-primary-error;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.order-sell-cloth-item {
  .item-image {
    .thumbnail-slider {
      background: $color-btn-disabled-background-primary;
      padding: 1.25rem;
      .slider-dots {
        bottom: .5rem;
        .dot {
          width: 4px;
          height: 4px;
          &:not(:first-child) {
            margin-left: 4.5px;
          }
        }
      }
    }
  }
}
</style>
  