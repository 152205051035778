<template>
  <div class="analyze-color-rank">
    <div class="item-nav">
      <h3 class="fs-h1"><span v-text="data[0].color.title"></span><span class="fs-14px"> ({{ data[0].count }}件)</span></h3>
      <div class="fs-h3">是我單品中最多的色系</div>
      <div class="color-icon" :style="{ background: data[0].color.color }"></div>
    </div>
    <div class="item-body">
      <div class="sub-title fs-14px">其他色系</div>
      <CustomHorizontalProgressBar v-for="item in filteredData" :key="item.color.title" :color="item.color.color" :percentage="item.percentage" :count="item.count" unit="件" />
    </div>
  </div>
</template>

<script>
import CustomHorizontalProgressBar from "@/components/CustomHorizontalProgressBar.vue";

export default {
  name: 'AnalyzeColorRank',
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomHorizontalProgressBar,
  },
  computed: {
    filteredData() {
      if (!this.data || this.data.length === 0) return [];
      const filtered = this.data.slice(1);

      // 找出最高 count 值
      const maxCount = filtered[0].count;

      // 計算百分比並返回新陣列
      return filtered.map(item => {
        const percentage = ((item.count / maxCount) * 100).toFixed(0);
        return {
          ...item,
          percentage: Number(percentage), // 將百分比值新增到物件中
        };
      });
    }
	},
  watch: {
  },
  async mounted() {
	},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.analyze-color-rank {
  .item-nav {
    position: relative;
    .fs-h3 {
      margin: 0;
    }
    .color-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }
  }
  .sub-title {
    color: $typography-secondary-default;
  }
  .item-body {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  .percentage-bar-group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .bar {
      height: 12px;
      border-radius: 6px;
      //transition: width 2s ease;
    }
    .desc {
      display: flex;
      justify-content: end;
      flex-basis: 40px;
      flex-shrink: 0;
      width: 40px;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.analyze-color-rank {

}
</style>
