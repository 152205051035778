<template>
  <div class="custom-horizontal-progress-bar">
    <div class="bar" :style="{ background: color, width: active ? `calc(${percentage * (animatedProgress)}% - ${descWidth + 8}px)` : '0px' }"></div>
    <div class="desc fs-14px" v-text="animatedCount + unit"></div>
    <!-- 隱藏的測量元素 -->
    <div ref="measure" class="measure fs-14px" style="visibility: hidden; position: absolute; white-space: nowrap;" v-text="count + unit"></div>
  </div>
</template>

<script>
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";

export default {
  name: 'CustomHorizontalProgressBar',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      active: false, // 控制動畫啟動
      animatedCount: 0, // 動畫中的計數
      animatedProgress: 0, // 動畫中的進度
      descWidth: 0, // desc 寬度
    };
  },
  props: {
    color: {
      type: String,
      required: false,
      default: '#EAB946',
    },
    percentage: {
      type: Number,
      required: false,
      default: 0,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
    unit: {
      type: String,
      required: false,
      default: '',
    },
    duration: {
      type: Number,
      required: false,
      default: 2000,
    },
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  async mounted() {
    this.calculateMaxDescWidth();
    this.useObserver(
        this.$el,
        () => {
          this.active = true;
          this.startAnimation();
        }
    );
  },
  methods: {
    calculateMaxDescWidth() {
      const measureElement = this.$refs.measure;

      // 測量內容的寬度
      this.descWidth = measureElement.offsetWidth;
    },
    startAnimation() {
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / this.duration, 1); // 進度最大為 1

        // 動態更新動畫數據
        this.animatedCount = Math.round(this.count * progress); // 計數動畫
        this.animatedProgress = progress; // 寬度動畫進度

        // 持續執行直到動畫完成
        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      // 啟動動畫
      requestAnimationFrame(animate);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-horizontal-progress-bar {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  .bar {
    height: 12px;
    border-radius: 6px;
    //transition: width 2s ease;
  }
  .desc {
    flex-shrink: 0;
    white-space: nowrap;
    padding-left: .5rem;
  }
  .measure {
    visibility: hidden;
    position: absolute;
    white-space: nowrap;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-horizontal-progress-bar {

}
</style>
  