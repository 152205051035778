<template>
  <div class="market-place-rewards">

    <div class="py-3">
      <b-button class="reward-info-btn fs-16px" variant="primary" block @click="openEditRewardInfoModal">
        <span>管理銀行資料</span>
      </b-button>
    </div>
    <p class="text-center" v-if="walletListData.length === 0">尚未建立任何錢包</p>

    <WalletCard 
      v-for="(wallet) in walletListData" 
      :key="wallet.serial" 
      :wallet="wallet" 
      @titleClicked="openWalletDetailModal"
      @withdrawClicked="openWithdrawSubmitModal"
    ></WalletCard>

    <transition name="fade">
      <FullScreenModal v-if="showWalletModal" @back="closeWalletDetailModal" title="回饋金紀錄">
        <WalletDetailModal :wallet="detailWallet" />
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="showWithdrawSubmitModal" @back="closeWithdrawSubmitModal" title="提領回饋金">
        <WithdrawSubmitModal :wallet="detailWallet" @close="closeWithdrawSubmitModal"/>
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="showEditRewardInfo" title="編輯匯款資料" @back="closeEditRewardInfoModal">
        <div class="edit-reward-info-modal">
          <div class="title">匯款資料</div>
          <CustomSelectGroup labelText="銀行代號" :options="bankOptions" v-model="submitUserBankAccount.bankCode" />
          <CustomInputGroup labelText="戶名" v-model="submitUserBankAccount.accountName" />
          <CustomInputGroup labelText="帳號" v-model="submitUserBankAccount.bankAccount" />
        </div>
        
        <div class="px-2 py-3" style="background-color: #f7f7f7; color: #404040;">
          <p class="mb-0">
            請注意：每次儲存後，系統只會保留您最新的匯款資料，店家將直接查看您最新的匯款資訊。請確認資料正確後再行儲存。
          </p>
        </div>
        <CustomModalFooter class="form-footer d-flex justify-content-between align-items-center flex-nowrap">
          <b-button variant="primary" block :disabled="!isBankAccountComplete" @click="submitBankData">
            <span>儲存</span>
          </b-button>
        </CustomModalFooter>
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import WalletCard from '@/components/share/WalletCard.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WalletDetailModal from './WalletDetailModal.vue';
import WithdrawSubmitModal from './WithdrawSubmitModal.vue';
import CustomInputGroup from "@/components/commons/CustomInputGroup.vue";
import CustomSelectGroup from "@/components/commons/CustomSelectGroup.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import bankList from "@/lib/bankList";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'MarketPlaceRewards',
  data() {
    return {
      readingBusyName: 'MARKETPLACEREWARDS',
      
      showWalletModal: false,
      showWithdrawSubmitModal: false,
      detailWallet: {},

      showEditRewardInfo: false,
      userBankAccount: null,
      submitUserBankAccount: {
        bankCode: '',
        bankName: '',
        bankAccount: '',
        accountName: '',
      },
      bankList: bankList,

      init: false,
    };
  },
  components: {
    WalletCard,
    FullScreenModal,
    WalletDetailModal,
    WithdrawSubmitModal,
    CustomInputGroup,
    CustomSelectGroup,
    CustomModalFooter,
  },
  computed: {
    ...mapState('wallet', ['walletListData']),
    bankOptions() {
      // 將 bankListData 轉換為 b-form-select 適用的格式
      return this.bankList.map(bank => ({
        value: bank.code, // 值為銀行代碼
        text: `${bank.code} - ${bank.name}`, // 顯示銀行代碼與名稱
      }));
    },
    isBankAccountComplete() {
      return Object.values(this.submitUserBankAccount).every(value => value.trim() !== '');
    },
  },
  watch: {
    "submitUserBankAccount.bankCode": function(val) {
      const bank = this.bankList.find((b) => {
        return b.code === val;
      });
      if (bank) {
        this.submitUserBankAccount.bankName = bank.name;
      } else {
        this.submitUserBankAccount.bankName = '';
      }
      
    }
  },
  async mounted() {
    await this.refreshWalletList();
    await this.readBankAccountInfo();
  },
  methods: {
    ...mapActions('wallet', ['refreshWalletList']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    async readBankAccountInfo() {
      this.userBankAccount = await this.$store.dispatch('api/getUserBankAccountPromise');
      this.updateSubmitUserBankAccount();
    },
    updateSubmitUserBankAccount() {
      this.submitUserBankAccount = JSON.parse(JSON.stringify({
        bankCode: this.userBankAccount.bankCode,
        bankName: this.userBankAccount.bankName,
        bankAccount: this.userBankAccount.bankAccount,
        accountName: this.userBankAccount.accountName
      }));
    },
    openWalletDetailModal(wallet) {
      this.detailWallet = Object.assign({}, this.detailWallet, wallet);
      this.showWalletModal = true;
    },
    openWithdrawSubmitModal(wallet) {
      this.detailWallet = Object.assign({}, this.detailWallet, wallet);
      this.showWithdrawSubmitModal = true;
    },
    closeWalletDetailModal() {
      this.showWalletModal = false;
    },
    closeWithdrawSubmitModal() {
      this.showWithdrawSubmitModal = false;
    },
    
    openEditRewardInfoModal() {
      this.showEditRewardInfo = true;
    },
    closeEditRewardInfoModal() {
      this.showEditRewardInfo = false;
    },
    async submitBankData() {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postUserBankAccountPromise', this.submitUserBankAccount);
        await this.readBankAccountInfo();
        this.closeEditRewardInfoModal();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.market-place-rewards {
  padding: 0 .5rem;
  .edit-reward-info-modal {
    padding: 1.5rem 1rem 5rem;
    .title {
      font-size: $font-large;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 1rem;
    }
  }
}
</style>
  