<template>
  <FullScreenModal class="create-outfit" :title="modalTitle" @back="backClicked">
    <div class="create-outfit-body">
      <div class="images-group">
        <CropImageButton @uploaded="outfitImgUploaded" v-if="!outfit.outfitPhotos[0] || outfit.outfitPhotos[0].type !== 'photo'">
          <div class="collection-image upload-btn d-flex flex-column justify-content-center align-items-center">
            <Icon name="Camera" size="24" />
            <div class="fs-16px">上傳封面照</div>
            <div class="fs-16px">記錄這一天</div>
          </div>
        </CropImageButton>
        <CropImageButton @uploaded="outfitImgUploaded" v-if="outfit && outfit.outfitPhotos[0] && outfit.outfitPhotos[0].type === 'photo'">
          <div class="collection-image">
            <img :src="outfitPhoto" alt="clothing image">
            <div class="delete-btn" @click.stop="removeCloth(-1)">
              <Icon name="close" size="24" />
            </div>
          </div>
        </CropImageButton>
        <div class="collection-image" v-for="(item, index) in outfitCloths" :key="index">
          <img :src="item.imgs[0]" alt="clothing image">
          <div class="delete-btn" @click.stop="removeCloth(index)">
            <Icon name="close" size="24" />
          </div>
        </div>
      </div>
      <button class="collect-btn btn-outline-primary btn-sm" @click="openCollectFromCloset">編輯穿搭單品</button>
    </div>

    <CollectFromCloset v-if="showCollectFromCloset" :collectLimit="12" :collectedList="[]" v-model="showCollectFromCloset" @uploadCollectedItem="uploadCollectedItem" />

    <transition name="fade">
      <WcCommonModal title="確定離開" v-if="showConfirmModal" @cancel="showConfirmModal = false" @ok="backConfirm" hide-header>
        <div class="text-center">
          未儲存資料<br/>
          要離開嗎？
        </div>
      </WcCommonModal>
    </transition>
  </FullScreenModal>
</template>

<script>
import FullScreenModal from '@/components/FullScreenModal.vue';
import CollectFromCloset from "@/components/CollectFromCloset.vue";
import outfitDefault from "@/dataModel/outfit";
import moment from "moment/moment";
import {mapActions, mapState} from "vuex";
import WcCommonModal from "@/components/commons/WcCommonModal.vue";
import CropImageButton from "@/components/cropImage/CropImageButton.vue";

export default {
  name: 'CreateCloth',
  data() {
    return {
      initBusyName: 'CREATEOUTFITINITREADING',
      uploadBusyName: 'CREATEOUTFITUPLOADINGIMAGE',
      saveBusyName: 'OUTFITSAVING',
      init: false,

      showCollectFromCloset: false,
      showConfirmModal: false,

      outfit: {},
      collectFromClosetList: [],
    };
  },
  components: {
    CropImageButton,
    WcCommonModal,
    FullScreenModal,
    CollectFromCloset,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    },
    createDefaultDate: {
      type: String,
      required: false,
      default: '',
    },
    step: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  created() {
    this.outfit = Object.assign({}, this.outfit, outfitDefault());
    if (this.isCreate && this.createDefaultDate.length > 0) {
      this.outfit.date = moment(this.createDefaultDate).format('YYYY-MM-DD');
    }
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.uploadBusyName);
    this.clearComponentBusy(this.saveBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      if (this.id !== -1) {
        const outfit = await this.$store.dispatch('api/readOutfitInfoPromise', this.id);
        outfit.outfitPhotos = outfit.outfitPhotos.map((o) => {
          if (o.type === 'photo') {
            return o;
          } else if (o.type === 'cloth') {
            return {
              type: o.type,
              clothSerial: o.clothSerial,
            }
          }
        });
        this.outfit = Object.assign({}, this.outfit, outfit);
      } else {
        this.showCollectFromCloset = this.step === 1;
      }
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }
  },
  computed: {
    ...mapState(['cloths']),

    isCreate() {
      return this.id === -1;
    },
    isEdit() {
      return this.id !== -1;
    },
    modalTitle() {
      if (this.isCreate) {
        return '新增記錄穿搭';
      }
      if (this.isEdit) {
        return '編輯記錄穿搭';
      }
      return '';
    },
    hasPhoto() {
      return this.outfit.outfitPhotos.length > 0;
    },
    outfitPhoto() {
      if (this.hasPhoto) {
        return this.outfit.outfitPhotos[0].imgUrl;
      }
      return '';
    },
    outfitCloths() {
      const res = [];
      for (const op of this.outfit.outfitPhotos) {
        if (op.type !== 'cloth') {
          continue;
        }
        const cloth = this.cloths.filter((c) => {
          return c.serial === op.clothSerial;
        });
        res.push(cloth[0]);
      }
      return res;
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),

    backClicked() {
      this.showConfirmModal = true;
    },
    backConfirm() {
      this.$emit('back');
    },
    outfitImgUploaded(data) {
      console.log(data)
      const {url} = data;
      if (this.hasPhoto && this.outfit.outfitPhotos[0] === 'photo') {
        this.outfit.outfitPhotos[0].imgUrl = url;
      } else {
        this.outfit.outfitPhotos.unshift({
          type: 'photo',
          imgUrl: url,
          tags: [],
        });
      }
    },
    uploadCollectedItem(clothSerials) {
      clothSerials.map((serial) => {
        const found = this.outfit.outfitPhotos.find((p) => {
          return p.type === 'cloth' && p.clothSerial === serial;
        });
        if (found === undefined || found === null) {
          this.outfit.outfitPhotos.push({
            type: 'cloth',
            clothSerial: serial,
          });
        }
      });
      // console.log(clothSerials);
      this.collectFromClosetList = clothSerials;
    },
    removeCloth(index) {
      let currentIndex = this.outfit?.outfitPhotos[0]?.type === 'photo' ? index + 1 : index

      // 確保當前項目存在
      const currentItem = this.outfit.outfitPhotos[currentIndex];
      if (!currentItem) return;

      // 檢查 type 是否為 'cloth'
      if (currentItem.type === 'cloth') {
        const serialToRemove = currentItem.clothSerial;

        // 從 collectFromClosetList 中移除匹配的 serial
        const serialIndex = this.collectFromClosetList.indexOf(serialToRemove);
        if (serialIndex !== -1) {
          this.collectFromClosetList.splice(serialIndex, 1);
        }
      }

      // 從 outfitPhotos 中移除當前項目
      this.outfit.outfitPhotos.splice(currentIndex, 1);
    },
    openCollectFromCloset() {
      this.showCollectFromCloset = true;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@font-face {
  font-family: Wweather;
  src: url('~@/assets/font/Writes_weather.ttf');
}
.create-outfit {
  .create-outfit-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1rem 6.5rem;
  }
  .images-group {
    position: relative;
    display: flex;
    align-items: center;
    gap: .5rem;
    width: calc(100% + 1rem);
    overflow-x: auto;
    padding-right: 1rem;
  }
  .collection-image {
    flex: 0 0 auto;
    background: $color-image-background;
    width: 109px;
    height: 109px;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    a {
      position: absolute;
      top: 0;
      right: 0;
    }
    .delete-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      color: $typography-primary-default;
    }
  }
  .upload-btn {
    color: $typography-secondary-default;
    background: transparent;
    border: 1px dashed $color-border;
    .svg-icon {
      margin-bottom: .5rem;
    }
  }
  .collect-btn {
    border-color: $color-border;
    border-radius: .5rem;
    &:not(:disabled):hover, &:focus, &:not(:disabled):not(.disabled):active {
      border-color: $color-border;
    }
    &:disabled, &.hover:disabled {
      border-color: $color-border;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
</style>
