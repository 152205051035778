<template>
  <div class="analyze-brand-rank">
    <div class="item-nav">
      <h3 class="fs-h1"><span v-text="filteredData[0].brand"></span><span class="fs-14px"> ({{ filteredData[0].count }}件)</span></h3>
      <div class="fs-h3">是我最愛的品牌</div>
    </div>
    <div class="item-body" ref="itemBody">
      <div class="data-item" :style="{ flex: '0 0 ' + dataItemWidth + 'px' }" v-for="item in filteredData" :key="item.brand">
        <CustomVerticalProgressBar :percentage="item.percentage" :count="item.count" unit="件" />
        <div class="brand-name fs-14px" :style="{ width: dataItemWidth + 'px' }" v-text="item.brand"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomVerticalProgressBar from "@/components/CustomVerticalProgressBar.vue";

export default {
  name: 'AnalyzeBrandRank',
  data() {
    return {
      dataItemWidth: 0,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomVerticalProgressBar,
  },
  computed: {
    filteredData() {
      if (!this.data || this.data.length === 0) return [];

      // 找出最高 count 值
      // const maxCount = Math.max(...this.data.map(item => item.count));
      const maxCount = this.data[0].count;

      // 計算百分比並返回新陣列
      return this.data.map(item => {
        const percentage = ((item.count / maxCount) * 100).toFixed(0);
        return {
          ...item,
          percentage: Number(percentage), // 將百分比值新增到物件中
        };
      });
    }
	},
  watch: {
  },
  async mounted() {
    this.observeItemBodyWidth();
	},
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    observeItemBodyWidth() {
      // 創建 ResizeObserver 監視 item-body 的寬度變化
      this.resizeObserver = new ResizeObserver(() => {
        this.calculateDataItemWidth();
      });

      // 開始監視 item-body 的變化
      this.resizeObserver.observe(this.$refs.itemBody);
    },
    calculateDataItemWidth() {
      // 計算 data-item 的寬度
      const itemBodyWidth = this.$refs.itemBody.offsetWidth;
      this.dataItemWidth = (itemBodyWidth - (this.filteredData.length - 1)*8)/this.filteredData.length;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.analyze-brand-rank {
  .item-nav {
    .fs-h3 {
      margin: 0;
    }
  }
  .item-body {
    display: flex;
    max-width: 100%;
    gap: .5rem;
    .data-item {
      //flex: 0 0 calc((100% - 32px)/5);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: .25rem;
      .brand-name {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.analyze-brand-rank {

}
</style>
