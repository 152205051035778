<template>
  <div>
    <div class="analyze-closet" v-if="calculateFinished">
      <div class="analyze-nav">
        <div class="main-panel-data d-flex justify-content-between">
          <div class="item d-flex align-items-center flex-column">
            <div class="data">
              <span class="fs-h1" v-text="clothInTimeRangeLength"></span>
              <span class="fs-h4">件</span>
            </div>
            <div class="name fs-14px">單品</div>
          </div>
          <div class="item d-flex align-items-center flex-column">
            <div class="data">
              <span class="fs-h1">{{ `${useRate.toFixed(1)}` }}</span>
              <span class="fs-h4">%</span>
            </div>
            <div class="name fs-14px">衣櫥使用率</div>
          </div>
          <div class="item d-flex align-items-center flex-column">
            <div class="data" v-b-tooltip.hover title="高，表示有明顯的使用偏好；低，表示衣物使用的分佈較均勻。">
              <span class="fs-h1">{{ useDistStr }}</span>
              <span class="fs-h4" style="vertical-align: top;">
                <!-- <b-icon icon="info-circle"></b-icon> -->
                <img style="width: 1rem;" :src="require(`@/assets/images/circle-information.png`)" alt="">
              </span>
            </div>
            <div class="name fs-14px">使用率集中度</div>
          </div>
        </div>

        <div v-if="inUseCloths.length === 0">
          <p class="text-center">
            數據還是空白的？<br/>
            快開始使用長腳衣櫥，讓你的衣物管理從零開始變得井井有條！
          </p>
        </div>

        <div class="cloth-statistics-chart-group d-flex flex-column align-items-center" v-if="inUseCloths.length > 0">
          <b-button-group class="chart-group-nav" size="sm">
            <b-button
                :variant="activeClothStatistics === 'month' ? 'primary' : 'outline-primary'"
                @click="setActiveClothStatistics('month')"
            >
              每月
            </b-button>
            <b-button
                :variant="activeClothStatistics === 'year' ? 'primary' : 'outline-primary'"
                @click="setActiveClothStatistics('year')"
            >
              每年
            </b-button>
          </b-button-group>

          <D3MyClothStatistics class="chart-group-body" v-if="activeClothStatistics === 'month'" :data="myStatisticsData" :chartWidth="myStatisticsData.length * 30"/>
          <D3MyClothYearStatistics class="chart-group-body" v-if="activeClothStatistics === 'year'" :data="myYearStatisticsData" />
        </div>

      </div>

      <div class="analyze-card-group" v-if="inUseCloths.length > 0">
        <div class="card-item">
          <div class="item-nav">
            <h3 class="fs-h3">衣櫥總價值</h3>
            <div class="price">
              <span class="fs-h1" v-text="clothTotalValueCommaString"></span>
              <span class="fs-h3 unit">元</span>
            </div>
          </div>

          <div class="item-body d-flex justify-content-between">
            <div class="percentage-chart-group" v-for="item in top3PriceSumCategories" :key="item.category1Name">
              <D3PercentageDonutChart :percentage="Number(item.priceSumPercent)" :size="72" :thickness="6" />
              <div class="fs-16px name">
                <span v-text="item.category1Name"></span>
                <!-- <span>(</span>
                <span v-text="item.count"></span>
                <span>件)</span> -->
              </div>
              <div class="fs-14px fw-bold price">
                <span v-text="'$' + priceToString(item.priceSum)"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="card-item">
          <div class="item-nav">
            <h3 class="fs-h3">單品平均價格</h3>
            <div class="price">
              <span class="fs-h1" v-text="clothAvgValueCommaString"></span>
              <span class="fs-h3 unit">元</span>
            </div>
          </div>

          <div class="item-body cloth-avg-value">
            <div class="fs-14px fw-bold">TOP 3 單品價格</div>
            <div class="top-3-cloth-group">
              <div class="cloth-item" v-for="item in top3ClothByPrice" :key="item.serial">
                <img :src="item.imgs[0]" :alt="item.category1Name">
                <div class="price fs-16px" v-text="'$' + priceToString(item.price)"></div>
              </div>
            </div>
            <CustomCollapse class="top-3-cloth-category-group" v-for="category in top3ClothCategoryByPriceSum" :key="category.category1">
              <template #toggle>
                <div class="cloth-category-item">
                  <div class="title fs-14px fw-bold" v-text="category.category1Name + '（' + category.count + '）'"></div>
                  <div class="number fs-14px" v-text="priceToString((category.priceSum / category.count).toFixed(0)) + '/件'"></div>
                  <div class="option">
                    <Icon name="chevron-down" size="32" />
                  </div>
                </div>
              </template>
              <template>
                <div class="cloth-item-group">
                  <div class="cloth-item" v-for="item in category.parsed" :key="item.category2">
                    <div class="title fs-14px" v-text="`${item.category2Name.length > 6? `${item.category2Name.substr(0, 5)}...` : item.category2Name} （${item.count}）`"></div>
                    <div class="number fs-14px" v-text="'共 ' + item.priceSum + ' 元・' + priceToString((item.priceSum / item.count).toFixed(0)) + '/件'"></div>
                  </div>
                </div>
              </template>
            </CustomCollapse>
          </div>
        </div>

        <AnalyzeColorRank :data="top4ClothColorRank" class="card-item" />

        <AnalyzeBrandRank :data="top5ClothBrandRank" class="card-item" />

        <AnalyzeCategoryRank :data="clothCategory1Rank" class="card-item" />

        <!-- <AnalyzeDurationRank class="card-item" /> -->

        <div class="card-item">
          <div class="item-nav">
            <h3 class="fs-h3">增添的衣物</h3>
            <div class="price">
              <span class="fs-h1"><span>{{ donutChartData[0].percentage }}</span>%</span>
              <span class="fs-h3 unit">是二手衣</span>
            </div>
          </div>

          <div class="item-body d-flex justify-content-center">
            <D3PercentageDistributionDonutChart :data="donutChartData" />
          </div>
        </div>

      </div> 
    </div>

  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import CustomCollapse from "@/components/CustomCollapse.vue";
import AnalyzeColorRank from "@/views/test/AnalyzeColorRank.vue";
import AnalyzeBrandRank from "@/views/test/AnalyzeBrandRank.vue";
import AnalyzeCategoryRank from "@/views/test/AnalyzeCategoryRank.vue";
// import AnalyzeDurationRank from "@/views/test/AnalyzeDurationRank.vue";
import D3MyClothStatistics from "@/views/test/D3MyClothStatistics.vue";
import D3MyClothYearStatistics from "@/views/test/D3MyClothYearStatistics.vue";
import D3PercentageDonutChart from "@/views/test/D3PercentageDonutChart.vue";
import D3PercentageDistributionDonutChart from "@/views/test/D3PercentageDistributionDonutChart.vue";

export default {
  name: 'AnalyzeClosetNew',
  data() {
    return {
      readingBusyName: 'READINGANALYZEDATA',
      outfits: [],

      calculateFinished: false,

      activeClothStatistics: 'month',
      myStatisticsData: [
        // { all: 109, new: 10, out: 5, month: 10, year: 2023 },
      ],
      myYearStatisticsData: [
        // { new: 35, out: 23, year: 2022 },
      ],

      donutChartData: [
        { name: '二手衣', percentage: 65 },
        { name: '新衣', percentage: 20 },
        { name: '無數據', percentage: 15 },
      ],

      inUseCloths: [],
      totalUseCount: 0,
      useRate: 0,
      useDist: 0,
    };
  },
  components: {
    CustomCollapse,
    AnalyzeColorRank,
    AnalyzeBrandRank,
    AnalyzeCategoryRank,
    // AnalyzeDurationRank,
    D3MyClothStatistics,
    D3MyClothYearStatistics,
    D3PercentageDonutChart,
    D3PercentageDistributionDonutChart,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
  },
  async mounted() {
    await this.__refresh();
  },
  computed: {
    ...mapGetters(['isBodyConfigShow']),
    ...mapState(['cloths', 'clothColors', 'clothCategories']),
    useDistStr() {
      if (this.useDist === -1) {
        return '-';
      } else if (this.useDist < 0.3) {
        return '低';
      } else {
        return '高';
      }
    },
    outfitClothArray() {
      const outfitCloths = this.outfits.reduce((ary, item) => {
        const {outfitPhotos} = item;
        for (const photo of outfitPhotos) {
          if (photo.type !== 'cloth') {
            continue;
          }
          const {clothSerial} = photo;
          const cloth = this.cloths.filter((c) => {
            return c.serial === clothSerial;
          })[0];
          if (!cloth.isDeleted && cloth.recycleType === -1) {
            ary.push(cloth);
          }
        }
        return ary;
      }, []);
      return outfitCloths;
    },
    outfitCategory2Summary() {
      const category2Ranking = this.outfitClothArray.reduce((ary, item) => {
        const {category2} = item;
        for (const r of ary) {
          if (r.category2 === category2) {
            r.count += 1;
            return ary;
          }
        }
        ary.push({
          category2,
          count: 1,
        });
        return ary;
      }, []);
      const sorted = category2Ranking.sort((a, b) => {
        return b.count - a.count;
      });
      return sorted;
    },
    outfitMostCategory2Name() {
      if (this.outfitCategory2Summary.length === 0) {
        return '';
      }
      const s = this.outfitCategory2Summary[0].category2;
      for (const c of this.clothCategories) {
        for (const c2 of c.sub) {
          if (c2.serial === s) {
            return c2.name;
          }
        }
      }
      return '';
    },
    outfitClothsSummary() {
      const clothRanking = this.outfitClothArray.reduce((ary, item) => {
        for (const r of ary) {
          if (r.clothSerial === item.serial) {
            r.count += 1;
            return ary;
          }
        }
        ary.push({
          clothSerial: item.serial,
          count: 1,
        });
        return ary;
      }, []);
      let sorted = clothRanking.sort((a, b) => {
        return b.count - a.count;
      });
      if (sorted.length > 3) {
        sorted = sorted.splice(0, 3);
      }
      for (const item of sorted) {
        item.cloth = this.cloths.filter((c) => {
          return c.serial === item.clothSerial;
        })[0];
      }
      return sorted;
    },
    outfitColorRank() {
      const colorRank = this.outfitClothArray.reduce((ary, cloth) => {
        for (let i=0;i<ary.length;i++) {
          if (ary[i].colorId === cloth.color) {
            ary[i].count += 1;
            return ary;
          }
        }
        const color = this.clothColors.filter((c) => {
          return c.id === cloth.color;
        });
        const colorCandidate = {
          colorId: cloth.color,
          count: 1,
          color: color[0],
        };
        ary.push(colorCandidate);
        return ary;
      }, []);
      const sortColorRank = colorRank.sort((a, b) => {
        return b.count - a.count;
      });
      return sortColorRank;
    },
    outfitColorMaxCount() {
      if (this.outfitColorRank.length > 0) {
        return this.outfitColorRank[0].count;
      }
      return 1;
    },


    clothInTimeRange() {
      const filterdCloths = this.cloths.filter((cloth) => {
        return cloth.recycleType === -1 && !cloth.isDeleted;
      });
      return filterdCloths;
    },
    clothInTimeRangeLength() {
      return this.clothInTimeRange.length;
    },
    clothTotalValue() {
      const value = this.clothInTimeRange.reduce((sum, cloth) => {
        return cloth.price > 0? sum + cloth.price: sum;
      }, 0);
      return value;
    },
    clothTotalValueCommaString() {
      return this.clothTotalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clothAvgValueCommaString() {
      return (this.clothTotalValue / this.clothInTimeRange.length).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clothColorRank() {
      const colorRank = this.clothInTimeRange.reduce((ary, cloth) => {
        for (let i=0;i<ary.length;i++) {
          if (ary[i].colorId === cloth.color) {
            ary[i].count += 1;
            return ary;
          }
        }
        const color = this.clothColors.filter((c) => {
          return c.id === cloth.color;
        });
        const colorCandidate = {
          colorId: cloth.color,
          count: 1,
          color: color[0],
        };
        ary.push(colorCandidate);
        return ary;
      }, []);
      const sortColorRank = colorRank.sort((a, b) => {
        return b.count - a.count;
      });
      return sortColorRank;
    },
    clothColorMaxCount() {
      if (this.clothColorRank.length > 0) {
        return this.clothColorRank[0].count;
      }
      return 1;
    },
    clothBrandRank() {
      const brandRank = this.clothInTimeRange.reduce((ary, cloth) => {
        for (const brand of cloth.brands) {
          let found = false;
          for (let i=0;i<ary.length;i++) {
            if (ary[i].brand === brand) {
              found = true;
              ary[i].count += 1;
              break;
            }
          }
          if (!found) {
            ary.push({
              brand,
              count: 1,
            });
          }
        }
        return ary;
      }, []);
      const sortBrandRank = brandRank.sort((a, b) => {
        return b.count - a.count;
      });
      return sortBrandRank.length > 5 ? sortBrandRank.splice(0, 5): sortBrandRank;
    },
    clothBrandMaxCount() {
      if (this.clothBrandRank.length > 0) {
        return this.clothBrandRank[0].count;
      }
      return 1;
    },
    clothCategory1Rank() {
      const clothCategory1Rank = this.clothInTimeRange.reduce((ary, cloth) => {
        const {category1} = cloth;
        for (const a of ary) {
          if (a.category1 === category1) {
            a.count += 1;
            a.priceSum += cloth.price > 0 ? cloth.price : 0;
            a.sub.push(cloth);
            return ary;
          }
        }
        const category1Name = this.__findCategory1Name(category1);
        ary.push({
          category1,
          category1Name,
          sub: [cloth],
          count: 1,
          priceSum: cloth.price > 0 ? cloth.price : 0,
        });
        return ary;
      }, []);

      for (const c of clothCategory1Rank) {
        c.parsed = this.__subParse(c.sub);
      }
      return clothCategory1Rank.sort((a, b) => { return b.count - a.count });
    },
    top3PriceSumCategories() {
      const totalValue = this.clothTotalValue;
      return [...this.clothCategory1Rank] // 使用展開運算符複製陣列
          .sort((a, b) => b.priceSum - a.priceSum)
          .slice(0, 3)
          .map(item => ({
            category1Name: item.category1Name,
            count: item.count,
            priceSum: item.priceSum,
            priceSumPercent: totalValue
                ? ((item.priceSum / totalValue) * 100).toFixed(0)
                : 0, // 計算百分比
          }));
    },
    top3ClothByPrice() {
      return this.clothInTimeRange
          .slice() // 建立副本
          .sort((a, b) => b.price - a.price) // 按價格降序排列
          .slice(0, 3) // 取前3個物件
          .map(item => ({
            price: item.price,
            imgs: item.imgs,
            category1Name: this.__findCategory1Name(item.category1)
          }));
    },
    top3ClothCategoryByPriceSum() {
      return this.clothCategory1Rank
          .slice() // 複製原陣列以避免直接修改原陣列
          .sort((a, b) => b.priceSum - a.priceSum) // 根據 priceSum 降序排列
          .slice(0, 3); // 取前3個物件
    },
    top4ClothColorRank() {
      const colorMap = new Map();

      this.clothInTimeRange.forEach(cloth => {
        const colorId = cloth.color;
        const color = this.clothColors.find(c => c.id === colorId);

        if (colorMap.has(colorId)) {
          colorMap.get(colorId).count += 1;
        } else {
          colorMap.set(colorId, {
            colorId: colorId,
            count: 1,
            color: color,
          });
        }
      });

      // 將 Map 轉換為陣列並根據 count 進行排序，取前 4 名
      const sortColorRank = [...colorMap.values()]
          .sort((a, b) => b.count - a.count)
          .slice(0, 4);

      return sortColorRank;
    },
    top5ClothBrandRank() {
      const brandCountMap = new Map();

      // 使用 Map 儲存品牌和其計數
      this.clothInTimeRange.forEach((cloth) => {
        cloth.brands.forEach((brand) => {
          brandCountMap.set(brand, (brandCountMap.get(brand) || 0) + 1);
        });
      });

      // 將 Map 轉為陣列並排序
      const sortedBrandRank = [...brandCountMap.entries()]
          .map(([brand, count]) => ({ brand, count })) // 格式化為對象
          .sort((a, b) => b.count - a.count); // 按 count 排序

      // 返回前 5 名
      return sortedBrandRank.slice(0, 5);
    },

    clothRemainRank() {
      const now = moment();
      const clothRemainRank = this.clothInTimeRange.reduce((ary, cloth) => {
        let type = 4;
        if (cloth.getYear !== -1 && cloth.getMonth !== -1) {
          const clothGetTime = moment().year(cloth.getYear).month(cloth.getMonth - 1);
          const diffYear = moment.duration(now.diff(clothGetTime)).asYears();
          if (diffYear < 1) {
            type = 0;
          } else if (diffYear < 2) {
            type = 1;
          } else if (diffYear < 3) {
            type = 2;
          } else {
            type = 3;
          }
        }
        ary[type].count += 1;
        ary[type].sub.push(cloth);
        return ary;
      }, [{
        title: '1年內',
        count: 0,
        sub: [],
      }, {
        title: '1-2年內',
        count: 0,
        sub: [],
      }, {
        title: '2-3年內',
        count: 0,
        sub: [],
      }, {
        title: '超過3年',
        count: 0,
        sub: [],
      }, {
        title: '無數據',
        count: 0,
        sub: [],
      }]);

      for (const c of clothRemainRank) {
        c.parsed = this.__subParse(c.sub);
      }
      return clothRemainRank;
    },
    mostClothRemainRank() {
      let maxName = '';
      let maxCount = 0;
      for (let i=0;i<this.clothRemainRank.length;i++) {
        if (this.clothRemainRank[i].count > maxCount && this.clothRemainRank[i].title !== '無數據') {
          maxCount = this.clothRemainRank[i].count;
          maxName = this.clothRemainRank[i].title;
        }
      }
      return maxName;
    },
    clothGetStatusRank() {
      const clothGetStatusRank = this.clothInTimeRange.reduce((ary, cloth) => {
        const {getStatus} = cloth;
        for (const a of ary) {
          if (a.getStatus === getStatus) {
            a.count += 1;
            a.sub.push(cloth);
            return ary;
          }
        }
        return ary;
      }, [{
        title: '新衣',
        count: 0,
        getStatus: 0,
        sub: [],
      }, {
        title: '二手衣',
        count: 0,
        getStatus: 1,
        sub: [],
      }, {
        title: '無分類',
        count: 0,
        getStatus: -1,
        sub: [],
      }]);

      for (const c of clothGetStatusRank) {
        c.parsed = this.__subParse(c.sub);
      }
      return clothGetStatusRank;
    },
    mostClothGetStatusRank() {
      let maxName = '';
      let maxCount = 0;
      for (let i=0;i<this.clothGetStatusRank.length;i++) {
        if (this.clothGetStatusRank[i].count > maxCount && this.clothGetStatusRank[i].title !== '無分類') {
          maxCount = this.clothGetStatusRank[i].count;
          maxName = this.clothGetStatusRank[i].title;
        }
      }
      return maxName;
    },
  },
  watch: {
    isBodyConfigShow(val) {
      if (!val) {
        this.__refresh();
      }
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    setActiveClothStatistics(type) {
      this.activeClothStatistics = type;
    },
    priceToString(number) {
      if (number >= 10000) {
        return `${(number / 10000).toFixed(1)}萬`; 
      } else {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    calculateAllData() {


      this.myStatisticsData.splice(0);
      this.myYearStatisticsData.splice(0);
      this.donutChartData.splice(0);
      this.inUseCloths.splice(0);

      let totalInTimeClothCount = 0;
      const getStatusArray = [
      {
          name: '二手衣',
          percentage: 0,
          count: 0,
        },
        {
          name: '新衣',
          percentage: 0,
          count: 0,
        },
        {
          name: '無數據',
          percentage: 0,
          count: 0,
        },
      ];

      for (const c of this.cloths) {

        let newCount = 0;
        let outCount = 0;
        let createMoment = null;
        if (c.available && c.recycleType === -1) {
          newCount = 1;
          createMoment = moment(c.create_at);

          totalInTimeClothCount += 1;
          const getStatus = c.getStatus;
          if (getStatus === -1) {
            getStatusArray[2].count += 1;
          } else if (getStatus === 0) {
            getStatusArray[1].count += 1;
          } else if (getStatus === 1) {
            getStatusArray[0].count += 1;
          }

          this.inUseCloths.push({
            cloth: c,
            useCount: 0,
          });
        } else if (c.available && c.recycleType !== -1) {
          outCount = 1;
          createMoment = moment(c.recycleTime);
        }


        const getYear = createMoment.year();
        const getMonth = createMoment.month() + 1;

        if (!isNaN(getYear) && !isNaN(getMonth)) {

          let monthFound = false;
          for (let i=0;i<this.myStatisticsData.length;i++) {
            if (this.myStatisticsData[i].year === getYear && this.myStatisticsData[i].month === getMonth) {
              monthFound = true;
              this.myStatisticsData[i].new += newCount;
              this.myStatisticsData[i].out += outCount;
              break;
            }
          }
          if (!monthFound) {
            this.myStatisticsData.push({
              year: getYear,
              month: getMonth,
              new: newCount,
              out: outCount,
              all: 0,
            });
          }
  
          let yearFound = false;
          for (let i=0;i<this.myYearStatisticsData.length;i++) {
            if (this.myYearStatisticsData[i].year === getYear) {
              yearFound = true;
              this.myYearStatisticsData[i].new += newCount;
              this.myYearStatisticsData[i].out += outCount;
              break;
            }
          }
          if (!yearFound) {
            this.myYearStatisticsData.push({
              year: getYear,
              new: newCount,
              out: outCount,
              all: 0,
            });
          }
        }
      }

      
      // myStatisticsData
      this.myStatisticsData.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        } else {
          return a.year - b.year;
        }
      });
      this.myYearStatisticsData.sort((a, b) => {
        return a.year - b.year;
      });
      let lastSum = 0;
      for (let i=0;i<this.myStatisticsData.length; i++) {
        this.myStatisticsData[i].all = lastSum + this.myStatisticsData[i].new - this.myStatisticsData[i].out;
        lastSum = this.myStatisticsData[i].all;
        if (i<(this.myStatisticsData.length - 1)) {
          const next = this.myStatisticsData[i+1];
          let nextMonth = this.myStatisticsData[i].month + 1;
          let nextYear = this.myStatisticsData[i].year;
          if (nextMonth > 12) {
            nextMonth -= 12;
            nextYear += 1;
          }
          if (next.year !== nextYear || next.month !== nextMonth) {
            this.myStatisticsData.splice(i+1, 0, {
              year: nextYear,
              month: nextMonth,
              new: 0,
              out: 0,
              all: 0,
            });
          }
        }
      }
      lastSum = 0;
      for (let i=0;i<this.myYearStatisticsData.length; i++) {
        this.myYearStatisticsData[i].all = lastSum + this.myYearStatisticsData[i].new - this.myYearStatisticsData[i].out;
        lastSum = this.myYearStatisticsData[i].all;
        if (i<(this.myYearStatisticsData.length - 1)) {
          const next = this.myYearStatisticsData[i+1];
          let nextYear = this.myYearStatisticsData[i].year + 1;
          if (next.year !== nextYear) {
            this.myYearStatisticsData.splice(i+1, 0, {
              year: nextYear,
              new: 0,
              out: 0,
              all: 0,
            });
          }
        }
      }

      for (let i=0;i<getStatusArray.length;i++) {
        getStatusArray[i].percentage = parseInt(getStatusArray[i].count * 100 / totalInTimeClothCount);
        this.donutChartData.push(getStatusArray[i]);
      }

      this.totalUseCount = 0;
      for (let i=0;i<this.inUseCloths.length;i++) {
        const serial = this.inUseCloths[i].cloth.serial;
        for (const o of this.outfits) {
          const {outfitPhotos} = o;
          for (const photo of outfitPhotos) {
            if (photo.type !== 'cloth') {
              continue;
            }
            if (photo.clothSerial === serial) {
              this.totalUseCount += 1;
              this.inUseCloths[i].useCount += 1;
            }
          }
        }
      }

      const noUseCloths = this.inUseCloths.filter( c => {
        return c.useCount === 0;
      });
      this.useRate = (1 - (noUseCloths.length / this.inUseCloths.length)) * 100;
      const maxUse = this.inUseCloths.reduce((max, item) => {
        return Math.max(max, item.useCount);
      }, 0);
      if (maxUse === 0) {
        this.useDist = -1;
      } else {
        let useAvg = this.totalUseCount / this.inUseCloths.length / maxUse;
        let sum = this.inUseCloths.reduce((s, item) => {
          return s + Math.pow(((item.useCount / maxUse) - useAvg), 2);
        }, 0);
        this.useDist = Math.sqrt(sum / this.inUseCloths.length);
      }
      

      this.calculateFinished = true;
      // setTimeout(() => {

      // }, 1000);
    },
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const list = await this.$store.dispatch('api/readOutfitListPromise');
        this.outfits.splice(0, this.outfits.length);
        for (const outfit of list) {
          this.outfits.push(outfit);
        }

        this.calculateAllData();
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    __subParse(cloths) {
      const res = [];
      for (const c of cloths) {
        const {category2} = c;
        let found = false;
        for (let i=0;i<res.length;i++) {
          if (res[i].category2 === category2) {
            found = true;
            res[i].count += 1;
            res[i].priceSum += c.price > 0 ? c.price : 0;
            break;
          }
        }
        if (!found) {
          const category2Name = this.__findCategory2Name(category2);
          res.push({
            category2,
            category2Name,
            count: 1,
            priceSum: c.price > 0 ? c.price : 0,
          });
        }
      }
      return res.sort((a, b) => { return b.count - a.count });
    },
    __findCategory1Name(category1) {
      for (const c of this.clothCategories) {
        if (c.serial === category1) {
          return c.name;
        }
      }
      return '';
    },
    __findCategory2Name(category2) {
      for (const c of this.clothCategories) {
        for (const c2 of c.sub) {
          if (c2.serial === category2) {
            return c2.name;
          }
        }
      }
      return '';
    },
  }
}
</script>

<style lang="scss">
  .main-content {
    overflow-y: hidden !important;
  }
</style>
<style lang="scss" scoped>
@import "src/assets/scss/basic";
.analyze-closet {
  background: $color-gray-light;
  padding: 0 0 2.5rem;
  .analyze-nav {
    background: $color-white;
    padding: 1rem;
    .main-panel-data {
      padding: .5rem;
      .item {
        width: 100px;
      }
      .data .fs-h4 {
        margin: 0 0 0 .5rem;
      }
      .name {
        color: $typography-secondary-default;
      }
    }
  }
  .cloth-statistics-chart-group {
    .chart-group-nav, .chart-group-body {
      padding: 1rem 0;
    }
    .chart-group-nav {
      width: 75%;
    }
  }
  .analyze-card-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    .card-item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: $color-white;
      border-radius: 1rem;
      padding: 1rem;
      .item-nav {
        h3.fs-h3 {
          margin: 0;
        }
        .price .unit {
          margin-left: .5rem;
        }
      }
      .item-body {
        &.cloth-avg-value {
          display: flex;
          flex-direction: column;
          gap: .5rem;
        }
      }
    }
  }
  .percentage-chart-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    .name {
      margin-top: .5rem;
    }
    .price {
      margin-top: .125rem;
    }
  }
  .top-3-cloth-group {
    display: flex;
    gap: .5rem;
    .cloth-item {
      position: relative;
      width: calc((100% - 1rem)/3);
      padding-top: calc((100% - 1rem)/3);
      border-radius: .625rem;
      overflow: hidden;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,.02), rgba(0,0,0,.1));
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .price {
        position: absolute;
        bottom: .25rem;
        left: 50%;
        color: $color-black-300;
        mix-blend-mode: difference; /* 根據背景亮暗自適應 */
        pointer-events: none; /* 確保文字層不阻擋圖片互動 */
        transform: translateX(-50%);
      }
    }
  }
  .top-3-cloth-category-group {
    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
    .cloth-category-item {
      display: flex;
      align-items: center;
      padding: .5rem;
      .title {
        flex: 4;
      }
      .number {
        display: flex;
        justify-content: end;
        flex: 3;
      }
      .option {
        display: flex;
        justify-content: end;
        flex: 3;
      }
    }
    .cloth-item-group {
      padding: 0 .5rem .5rem;
    }
    .cloth-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.analyze-closet {
  .btn-group-sm > .btn {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: .5rem;
    padding: .25rem 1rem;
  }
  .btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .collapse-toggle.active .cloth-category-item .option svg {
    transform: rotate(180deg);
  }
}
</style>

