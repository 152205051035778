import axios from 'axios';

const API_BASE_URL = '/closet/api';

export default {
  namespaced: true,
  state: {
    URLS: {

      LINELOGIN: `${API_BASE_URL}/lineLogin`,
      FBLOGIN: `${API_BASE_URL}/fbLogin`,
      GOOGLELOGIN: `${API_BASE_URL}/googleLogin`,
      EMAILLOGIN: `${API_BASE_URL}/emailLogin`,
      GETPROFILE: `${API_BASE_URL}/profile`,
      REFRESHTOKEN: `${API_BASE_URL}/refresh`,
      REGISTERBYEMAIL: `${API_BASE_URL}/auth/register`,
      FORGETPASSWORD: `${API_BASE_URL}/auth/forgetPswd`,
      RESETPSWDBYTOKEN: `${API_BASE_URL}/auth/resetPswd`,

      GETSHAREDOUTFITSERIALS: `${API_BASE_URL}/share/outfitSerials`,
      GETSHAREDOUTFITDETAIL: `${API_BASE_URL}/share/outfit`,
      GETRECENTFRIENDINFO: `${API_BASE_URL}/share/recentFriendInfo`,

      GETUSERDATA: `${API_BASE_URL}/user/me`,
      GETUSERPUBLICDATA: `${API_BASE_URL}/user/info`,
      POSTUSERDATA: `${API_BASE_URL}/user/update`,
      GETUSERPUBLICID: `${API_BASE_URL}/user/publicId`,
      POSTUSERPUBLICID: `${API_BASE_URL}/user/publicId`,
      POSTSEARCHPUBLICID: `${API_BASE_URL}/user/search`,
      RESETPASSWORD: `${API_BASE_URL}/user/resetPassword`,

      GETFOLLOWSERIALLIST: `${API_BASE_URL}/follow/listSerial`,
      POSTSETFOLLOW: `${API_BASE_URL}/follow/set`,
      GETFOLLOWSLIST: `${API_BASE_URL}/follow/follows`,
      GETFOLLOWERSLIST: `${API_BASE_URL}/follow/followers`,

      GETUSERBODYDATA: `${API_BASE_URL}/body/info`,
      POSTUSERBODYDATA: `${API_BASE_URL}/body/info`,
      POSTUSERHADA: `${API_BASE_URL}/body/hada`,

      GETUSERSIZEDATA: `${API_BASE_URL}/brandsize/list`,
      DELETEUSERSIZEDATA: `${API_BASE_URL}/brandsize/delete`,
      RECORDUSERSIZEDATA: `${API_BASE_URL}/brandsize/record`,

      GETPOSTLIST: `${API_BASE_URL}/blogger/list`,
      GETPOSTDETAIL: `${API_BASE_URL}/blogger/get`,

      GETCLOTHCATEGORY: `${API_BASE_URL}/config/category`,
      GETCLOTHCOLOR: `${API_BASE_URL}/config/color`,

      POSTUPLOADBASE64IMG: `${API_BASE_URL}/cloth/uploadBase64Img`,
      POSTUPLOADCLOTHIMAGE: `${API_BASE_URL}/cloth/uploadImg`,
      POSTUPLOADSUBCLOTHIMAGE: `${API_BASE_URL}/cloth/uploadSubImg`,
      POSTCREATECLOTH: `${API_BASE_URL}/cloth/create`,
      GETCLOTHLIST: `${API_BASE_URL}/cloth/list`,
      CLOTHINFO: `${API_BASE_URL}/cloth/info`,
      RECYCLECLOTH: `${API_BASE_URL}/cloth/recycle`,
      DELETECLOTH: `${API_BASE_URL}/cloth/delete`,
      PUBLICCLOTH: `${API_BASE_URL}/cloth/public`,
      QUICKLYCREATECLOTH: `${API_BASE_URL}/cloth/quick_create`,

      POSTUPLOADOUTFITIMAGE: `${API_BASE_URL}/outfit/uploadImg`,
      POSTOUTFITDATA: `${API_BASE_URL}/outfit/create`,
      GETOUTFITLIST: `${API_BASE_URL}/outfit/list`,
      GETOUTFITINFO: `${API_BASE_URL}/outfit/info`,
      POSTOUTFITINFO: `${API_BASE_URL}/outfit/info`,
      DELETEOUTFITDATA: `${API_BASE_URL}/outfit/delete`,

      LISTSHOPITEMS: `${API_BASE_URL}/delivery/shopItemList`,
      SHOPITEMINFO: `${API_BASE_URL}/delivery/shopItem`,
      BUYDELIVERYSHOPFAKE: `${API_BASE_URL}/delivery/fakeBuy`,
      GETDELIVERYLIST: `${API_BASE_URL}/delivery/list`,
      GETDELIVERYSUBMITINFO: `${API_BASE_URL}/delivery/info`,
      SENDDELIVERYCLOTHS: `${API_BASE_URL}/delivery/send`,
      SENDDELIVERYCONFIRM: `${API_BASE_URL}/delivery/confirm`,
      SENDDELIVERYIBONUPLOAD: `${API_BASE_URL}/delivery/ibonUpload`,
      REALSENDCLOTHNOTICE: `${API_BASE_URL}/delivery/noticeSend`,

      READTEMPLATE: `${API_BASE_URL}/epc/info`,
      BUYTEMPLATE: `${API_BASE_URL}/epc/buy`,

      CREATESHOPBILL: `${API_BASE_URL}/tappay/create`,
      PAYBILL: `${API_BASE_URL}/tappay/pay`,
      GETPAIDBILLCOUNT: `${API_BASE_URL}/tappay/paidAmount`,

      //抽屜
      getDrawerList: `${API_BASE_URL}/drawer/list`,
      postDrawerCreate: `${API_BASE_URL}/drawer/create`,
      postDrawerMoveInto: `${API_BASE_URL}/drawer/move`,
      postRemoveFromDrawer: `${API_BASE_URL}/drawer/remove`,
      postDrawerDelete: `${API_BASE_URL}/drawer/delete`,
      postDrawerUpdate: `${API_BASE_URL}/drawer/update`,

      //衣物審核
      getReviewEventList: `${API_BASE_URL}/reviewEvent/list`,
      getReviewEventDetail: `${API_BASE_URL}/reviewEvent/detail`,
      postReviewOrderCreate: `${API_BASE_URL}/reviewOrder/create`,
      getReviewOrderList: `${API_BASE_URL}/reviewOrder/list`,
      getReviewOrderDetail: `${API_BASE_URL}/reviewOrder/detail`,
      postReviewClothCreate: `${API_BASE_URL}/reviewCloth/appendClothInfo`,
      postReviewClothCreateFromCloset: `${API_BASE_URL}/reviewCloth/appendClothsInfoFromCloset`,
      postReviewClothUploadImage: `${API_BASE_URL}/reviewCloth/uploadClothImg`,
      postReviewClothUpdate: `${API_BASE_URL}/reviewCloth/updateSendingInfo`,
      postReviewClothRemove: `${API_BASE_URL}/reviewCloth/removeClothInfo`,
      postReviewOrderSendInfo: `${API_BASE_URL}/reviewOrder/sendInfo`,
      postReviewClothUpdateConfirm: `${API_BASE_URL}/reviewCloth/updateConfirm`,
      postReviewOrderSendBargain: `${API_BASE_URL}/reviewOrder/sendBargain`,
      postReviewOrderSendConfirm: `${API_BASE_URL}/reviewOrder/sendConfirm`,
      postReviewOrderSendTransferred: `${API_BASE_URL}/reviewOrder/sendTransferred`,
      postReviewOrderCancel: `${API_BASE_URL}/reviewOrder/cancel`,
      getReviewOrderPDF: `${API_BASE_URL}/reviewOrder/downloadPDF`,

      // 錢包
      getWalletList: `${API_BASE_URL}/wallet/list`,
      getWalletWithdarwList: `${API_BASE_URL}/wallet/withdrawList`,
      postSendWithdrawRequest: `${API_BASE_URL}/wallet/withdraw`,
    
      // 寄售單品
      getSellClothList: `${API_BASE_URL}/sellCloth/list`,
      getOrderSellClothList: `${API_BASE_URL}/sellCloth/inOrder`,

      // 使用者匯款帳號相關
      getUserBankAccount: `${API_BASE_URL}/userBankAccount/myAccount`,
      postUserBankAccount: `${API_BASE_URL}/userBankAccount/myAccount`,
    }
  },
  getters: {
    axiosConfig(state, getters, rootState) {
      return {
        headers: {
          'token': rootState.token,
          'Cache-Control': 'no-cache',
          // 'Content-Type': 'application/json',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postUploadFilePromise({getters}, {url, filename, file}) {
      const formData = new FormData();
      formData.append(filename, file);
      const axiosConfig = JSON.parse(JSON.stringify(getters.axiosConfig)); //使用深拷貝, 使其不影響其他引用getters.axiosConfig的未知錯誤
      axiosConfig.headers['Content-Type'] = 'multipart/form-data';
      return new Promise(function(resolve, reject) {
        axios.post(url, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getApiPromise({getters}, {url}) {
      return new Promise(function(resolve, reject) {
        axios.get(url, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getDownloadApiPromise({getters}, {url, filename}) {
      return new Promise(function(resolve) {
        axios.get(url, { ...getters.axiosConfig, responseType: 'blob'}).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
      
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
      
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          resolve();
        });
      });
    },

    postLineLoginPromise({dispatch, state}, {code, callbackUri}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LINELOGIN,
        data: {code, callbackUri}
      });
    },
    postFbLoginPromise({dispatch, state}, {code, callbackUri}) {
      return dispatch('postApiPromise', {
        url: state.URLS.FBLOGIN,
        data: {code, callbackUri}
      });
    },
    postGoogleLoginPromise({dispatch, state}, {credential}) {
      return dispatch('postApiPromise', {
        url: state.URLS.GOOGLELOGIN,
        data: {credential}
      });
    },
    postEmailLoginPromise({dispatch, state}, {email, password}) {
      return dispatch('postApiPromise', {
        url: state.URLS.EMAILLOGIN,
        data: {email, password}
      });
    },
    postRefreshToken({dispatch, state}, token) {
      return dispatch('postApiPromise', {
        url: state.URLS.REFRESHTOKEN,
        data: {token}
      });
    },
    postRegisterByEmail({dispatch, state}, email) {
      return dispatch('postApiPromise', {
        url: state.URLS.REGISTERBYEMAIL,
        data: {email}
      });
    },
    postResetPasswordByEmail({dispatch, state}, email) {
      return dispatch('postApiPromise', {
        url: state.URLS.FORGETPASSWORD,
        data: {email}
      });
    },
    postResetPasswordByEmailAndToken({dispatch, state}, {email, token, password}) {
      return dispatch('postApiPromise', {
        url: state.URLS.RESETPSWDBYTOKEN,
        data: {email, token, password}
      });
    },
    getUserDataPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETUSERDATA,
      });
    },
    getHomeOutfitSerialsPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETSHAREDOUTFITSERIALS,
      });
    },
    getHomeOutfitDetailPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETSHAREDOUTFITDETAIL}/${serial}`,
      });
    },
    getHomeRecentFriendInfoPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETRECENTFRIENDINFO}`,
      });
    },
    getUserPublicDataPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETUSERPUBLICDATA}/${serial}`,
      });
    },
    postUpdateUserDataPromise({dispatch, state}, user) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTUSERDATA,
        data: user
      });
    },
    getUserPublicIdPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETUSERPUBLICID,
      });
    },
    postUpdateUserPublicIcPromise({dispatch, state}, publicId) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTUSERPUBLICID,
        data: {publicId}
      });
    },
    postSearchUserPublicIdPromise({dispatch, state}, {publicId, precise}) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTSEARCHPUBLICID,
        data: {publicId, precise}
      });
    },
    postResetPasswordPromise({dispatch, state}, password) {
      return dispatch('postApiPromise', {
        url: state.URLS.RESETPASSWORD,
        data: {password}
      });
    },
    getFollowingSerialList({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETFOLLOWSERIALLIST,
      });
    },
    postSetFollowPromise({dispatch, state}, {follow, followedSerial}) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTSETFOLLOW,
        data: {follow, followedSerial}
      });
    },
    getFollowsList({dispatch, state}, userSerial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETFOLLOWSLIST}/${userSerial}`,
      });
    },
    getFollowersList({dispatch, state}, userSerial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETFOLLOWERSLIST}/${userSerial}`,
      });
    },
    getUserBodyDataPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETUSERBODYDATA,
      });
    },
    postUpdateUserBodyDataPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTUSERBODYDATA,
        data,
      });
    },
    postUpdateUserHadaPromise({dispatch, state}, hada) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTUSERHADA,
        data: {hada},
      });
    },
    readClothCategoryPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETCLOTHCATEGORY,
      });
    },
    readClothColorPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETCLOTHCOLOR,
      });
    },
    readProfilePromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETPROFILE,
      });
    },
    readPostListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETPOSTLIST,
      });
    },
    readPostDetailPromise({dispatch, state}, id) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETPOSTDETAIL}?id=${id}`,
      });
    },
    postUploadBase64ImagePromise({dispatch, state}, {img, removeBg}) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTUPLOADBASE64IMG,
        data: {img, removeBg},
      });
    },
    postUploadImagePromise({state, getters}, file) {
      const formData = new FormData();
      formData.append("filetoupload", file);
      const axiosConfig = {
        headers: {
          'token': getters.axiosConfig.headers.token,
          'Cache-Control': 'no-cache',
          'Content-Type': 'multipart/form-data',
        }
      };
      return new Promise(function(resolve, reject) {
        axios.post(state.URLS.POSTUPLOADCLOTHIMAGE, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postUploadSubImagePromise({state, getters}, file) {
      const formData = new FormData();
      formData.append("filetoupload", file);
      const axiosConfig = {
        headers: {
          'token': getters.axiosConfig.headers.token,
          'Cache-Control': 'no-cache',
          'Content-Type': 'multipart/form-data',
        }
      };
      return new Promise(function(resolve, reject) {
        axios.post(state.URLS.POSTUPLOADSUBCLOTHIMAGE, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postCreateClothPromise({dispatch, state}, cloth) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTCREATECLOTH,
        data: cloth,
      });
    },
    postQuickCreateClothPromise({dispatch, state}, {category1, category2, color, imgData}) {
      return dispatch('postApiPromise', {
        url: state.URLS.QUICKLYCREATECLOTH,
        data: {
          category1, category2, color, imgData
        },
      });
    },
    readClothListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETCLOTHLIST,
      });
    },
    readClothInfoPromise({dispatch, state}, id) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.CLOTHINFO}/${id}`,
      });
    },
    updateClothInfoPromise({dispatch, state}, {id, cloth}) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.CLOTHINFO}/${id}`,
        data: cloth,
      });
    },
    recycleClothPromise({dispatch, state}, {serial, recycleType}) {
      return dispatch('postApiPromise', {
        url: state.URLS.RECYCLECLOTH,
        data: {
          serial,
          recycleType
        },
      });
    },
    recoverClothPromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.RECYCLECLOTH,
        data: {
          serial,
          recycleType: -1,
        },
      });
    },
    deleteClothPromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.DELETECLOTH,
        data: {
          serial,
        },
      });
    },
    publicClothPromise({dispatch, state}, {serial, isPublic}) {
      return dispatch('postApiPromise', {
        url: state.URLS.PUBLICCLOTH,
        data: {
          serial,
          isPublic
        },
      });
    },
    readBrandSizeListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETUSERSIZEDATA,
      });
    },
    deleteBrandSizePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.DELETEUSERSIZEDATA,
        data: {
          serial,
        },
      });
    },
    recordBrandSizePromise({dispatch, state}, {brandName, sizeType, size}) {
      return dispatch('postApiPromise', {
        url: state.URLS.RECORDUSERSIZEDATA,
        data: {
          brandName,
          sizeType,
          size,
        },
      });
    },
    postCreateOutfitPromise({dispatch, state}, outfit) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTOUTFITDATA,
        data: outfit,
      });
    },
    readOutfitListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETOUTFITLIST,
      });
    },
    readOutfitInfoPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETOUTFITINFO}/${serial}`,
      });
    },
    updateOutfitPromise({dispatch, state}, {id, outfit}) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.POSTOUTFITINFO}/${id}`,
        data: outfit,
      });
    },
    deleteOutfitPromise({dispatch, state}, id) {
      return dispatch('postApiPromise', {
        url: state.URLS.DELETEOUTFITDATA,
        data: {id},
      });
    },
    readShopItemListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.LISTSHOPITEMS,
      });
    },
    readShopItemInfoPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.SHOPITEMINFO}/${serial}`,
      });
    },
    buyDeliveryShopItemFakePromise({dispatch, state}, id) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.BUYDELIVERYSHOPFAKE}/${id}`,
        data: {},
      });
    },
    readDeliveryListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETDELIVERYLIST,
      });
    },
    readDeliverySubmitInfoPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETDELIVERYSUBMITINFO}/${serial}`,
      });
    },
    sendDeliveryClothPromise({dispatch, state}, {serial, name, email, phone, cloths}) {
      return dispatch('postApiPromise', {
        url: state.URLS.SENDDELIVERYCLOTHS,
        data: {
          serial,
          name,
          email,
          phone,
          cloths
        }
      });
    },
    sendDeliveryConfirmPromise({dispatch, state}, {serial, disagreeSerials}) {
      return dispatch('postApiPromise', {
        url: state.URLS.SENDDELIVERYCONFIRM,
        data: {
          serial,
          disagreeSerials
        }
      });
    },
    sendDeliveryIconUploadPromise({dispatch, state}, {serial}) {
      return dispatch('postApiPromise', {
        url: state.URLS.SENDDELIVERYIBONUPLOAD,
        data: {
          serial,
        }
      });
    },
    sendDeliveryClothSendNoticePromise({dispatch, state}, {serial, sendDate, sendMethod}) {
      return dispatch('postApiPromise', {
        url: state.URLS.REALSENDCLOTHNOTICE,
        data: {
          serial,
          sendDate,
          sendMethod,
        }
      });
    },
    postUploadOutfitImagePromise({state, getters}, file) {
      const formData = new FormData();
      formData.append("filetoupload", file);
      const axiosConfig = {
        headers: {
          'token': getters.axiosConfig.headers.token,
          'Cache-Control': 'no-cache',
          'Content-Type': 'multipart/form-data',
        }
      };
      return new Promise(function(resolve, reject) {
        axios.post(state.URLS.POSTUPLOADOUTFITIMAGE, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    readTemplateByEpcPromise({dispatch, state}, epc) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.READTEMPLATE}/${epc}`,
      });
    },
    buyTemplateByEpcPromise({dispatch, state}, epc) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.BUYTEMPLATE}`,
        data: {
          epc,
        }
      });
    },
    createShopBillPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.CREATESHOPBILL}`,
        data,
      });
    },
    payBillPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.PAYBILL}`,
        data,
      });
    },
    getPaidBillAmountPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GETPAIDBILLCOUNT}/${serial}`,
      });
    },

    //抽屜
    getDrawerListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getDrawerList,
      });
    },

    postDrawerCreatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postDrawerCreate,
        data,
      });
    },

    postDrawerMoveIntoPromise({dispatch, state}, { to, cloths }) {
      return dispatch('postApiPromise', {
        url: state.URLS.postDrawerMoveInto,
        data: {
          to,
          clothes: cloths
        }
      });
    },

    postRemoveFromDrawerPromise({dispatch, state}, cloths ) {
      return dispatch('postApiPromise', {
        url: state.URLS.postRemoveFromDrawer,
        data: {
          clothes: cloths
        }
      });
    },

    postDrawerDeletePromise({dispatch, state}, serial ) {
      return dispatch('postApiPromise', {
        url: state.URLS.postDrawerDelete,
        data: {
          serial
        }
      });
    },

    postDrawerUpdatePromise({dispatch, state}, {serial, name} ) {
      return dispatch('postApiPromise', {
        url: state.URLS.postDrawerUpdate,
        data: {
          serial,
          name,
        }
      });
    },
    
    

    //衣物審核
    getReviewEventListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getReviewEventList,
      });
    },
    getReviewEventDetailPromise({dispatch, state}, id) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.getReviewEventDetail}/${id}`,
      });
    },
    postReviewOrderCreatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewOrderCreate,
        data,
      });
    },
    getReviewOrderListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getReviewOrderList,
      });
    },
    getReviewOrderDetailPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.getReviewOrderDetail}/${serial}`,
      });
    },

    getReviewOrderPDFPromise({dispatch, state}, {serial, filename}) {
      return dispatch('getDownloadApiPromise', {
        url: `${state.URLS.getReviewOrderPDF}/${serial}`,
        filename,
      });
    },
    postReviewClothCreatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewClothCreate,
        data,
      });
    },
    postReviewClothCreateFromCloset({dispatch, state}, { orderSerial, clothSerials }) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewClothCreateFromCloset,
        data: { orderSerial, clothSerials },
      });
    },
    postReviewClothUploadImagePromise({dispatch, state}, uploadImage) {
      return dispatch('postUploadFilePromise', {
        url: state.URLS.postReviewClothUploadImage,
        filename: 'uploadImage',
        file: uploadImage,
      });
    },
    postReviewClothUpdatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewClothUpdate,
        data,
      });
    },
    postReviewClothRemovePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewClothRemove,
        data,
      });
    },
    postReviewOrderSendInfoPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewOrderSendInfo,
        data,
      });
    },
    postReviewClothUpdateConfirmPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewClothUpdateConfirm,
        data,
      });
    },
    postReviewOrderSendBargainPromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewOrderSendBargain,
        data: { serial }
      });
    },
    postReviewOrderSendConfirmPromise({dispatch, state}, {serial, agreeId}) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewOrderSendConfirm,
        data: { serial, agreeId }
      });
    },
    postReviewOrderSendTransferredPromise({dispatch, state}, {serial, sendClothType, sendClothCode, sendClothDate, sendClothCodeImg}) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewOrderSendTransferred,
        data: { serial, sendClothType, sendClothCode, sendClothDate, sendClothCodeImg }
      });
    },
    postReviewOrderCancelPromise({dispatch, state}, orderSerial) {
      return dispatch('postApiPromise', {
        url: state.URLS.postReviewOrderCancel,
        data: { orderSerial }
      });
    },

    // 錢包相關
    getWalletListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getWalletList,
      });
    },
    
    getWalletWithdrawListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getWalletWithdarwList,
      });
    },
    
    postSendWithdrawRequestPromise({dispatch, state}, {businessAccountSerial, bankCode, bankName, bankAccount, accountName, totalWithdrawAmount}) {
      return dispatch('postApiPromise', {
        url: state.URLS.postSendWithdrawRequest,
        data: { businessAccountSerial, bankCode, bankName, bankAccount, accountName, totalWithdrawAmount }
      });
    },
    

    // 寄售單品相關
    getSellClothListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getSellClothList,
      });
    },

    getOrderSellClothListPromise({dispatch, state}, orderSerial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.getOrderSellClothList}/${orderSerial}`,
      });
    },

    // 使用者匯款帳號相關
    getUserBankAccountPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getUserBankAccount,
      });
    },

    postUserBankAccountPromise({dispatch, state}, {bankCode, bankName, bankAccount, accountName}) {
      return dispatch('postApiPromise', {
        url: state.URLS.postUserBankAccount,
        data: { bankCode, bankName, bankAccount, accountName }
      });
    },
  },

}
