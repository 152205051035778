<template>
  <div class="analyze-duration-rank">
    <div class="item-nav">
      <h3 class="fs-h3">單品平均擁有時間</h3>
      <div class="duration">
        <span class="fs-h1">0</span>
        <span class="fs-h3 unit">年內</span>
      </div>
    </div>
    <div class="item-body">
      <div class="duration-rank-bar-group">
        <div class="text">
        </div>
        <div class="bar">
          <div class="bar-item" style="opacity: 1"></div>
          <div class="text fs-12px fw-bold">
            <span>1年</span>
          </div>
          <div class="bar-item" style="opacity: .75"></div>
          <div class="text fs-12px fw-bold">
            <span>2年</span>
          </div>
          <div class="bar-item" style="opacity: .75"></div>
          <div class="text fs-12px fw-bold">
            <span>3年</span>
          </div>
          <div class="bar-item" style="opacity: .25"></div>
        </div>
      </div>

      <div class="duration-desc d-flex justify-content-between">
        <div class="fs-14px">
          大分類（0件）
        </div>
        <div class="fs-14px">
          0/月/件
        </div>
      </div>

      <div class="duration-desc d-flex justify-content-between">
        <div class="fs-14px">
          帽衣（0件）
        </div>
        <div class="fs-14px">
          0/月/件
        </div>
      </div>

      <div class="duration-desc d-flex justify-content-between">
        <div class="fs-14px">
          上衣（0件）
        </div>
        <div class="fs-14px">
          0/月/件
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyzeDurationRank',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
  },
  computed: {
	},
  watch: {
  },
  async mounted() {
	},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.analyze-duration-rank {
  .item-nav {
    .fs-h1, .fs-h3 {
      margin: 0;
    }
  }
  .duration {
    .unit {
      margin-left: .5rem;
    }
  }
  .item-body {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  .duration-rank-bar-group {
    padding: 1.625rem 0 .5rem;
    .bar {
      display: flex;
      width: 100%;
      height: 12px;
      .bar-item {
        flex: 1;
        height: 100%;
        background: $color-main-orange;
        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
      .text {
        position: relative;
        flex: 0 0 1px;
        width: 1px;
        span {
          position: absolute;
          top: -1.375rem;
          left: 50%;
          white-space: nowrap;
          transform: translateX(-50%);
        }
      }
    }
  }
  .duration-desc {
    padding: 0 .5rem;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.analyze-duration-rank {

}
</style>
