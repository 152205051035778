<template>
  <div class="service-provider">
    <div class="provider-image">
      <img :src="serviceData.accountInfo.profileBanner" alt="provider image">
    </div>
    <div class="provider-info">
      <h2 class="name fs-h2" v-text="serviceData.accountInfo.name"></h2>
      <div class="desc fs-14px" v-text="serviceData.accountInfo.desc">二手 / 選物 / 無包裝商店</div>
    </div>
    <div class="provider-list">
      <div class="provider-item d-flex flex-nowrap" :class="{'provider-item-test': item.status === 0}" v-for="(item, index) in serviceData.events" :key="index">
        <div class="item-image">
          <img :src="item.displayImg" alt="service image">
        </div>
        <div class="item-info d-flex flex-column justify-content-between">
          <div class="type fs-14px fw-bold" v-text="getTypeName(item.type)">[寄售]</div>
          <div class="name fs-18px fw-bold" v-text="item.displayName">男裝</div>
          <div class="price fs-16px fw-bold" v-text="'$' + item.reviewPrice">$0</div>
        </div>
        <div class="item-option d-flex align-items-center">
          <b-button class="fw-bold" variant="outline-primary" size="sm" @click="serviceConfirmClicked(item)">
            <span>申請</span>
          </b-button>
        </div>

        <div class="provider-test" v-if="item.status === 0">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 2C6.82386 2 7.5 2.67614 7.5 3.5V5H4.5V3.5C4.5 2.67614 5.17614 2 6 2ZM8.5 5V3.5C8.5 2.12386 7.37614 1 6 1C4.62386 1 3.5 2.12386 3.5 3.5V5H3C2.44772 5 2 5.44772 2 6V10C2 10.5523 2.44772 11 3 11H9C9.55228 11 10 10.5523 10 10V6C10 5.44771 9.55228 5 9 5H8.5ZM3 6H9V10H3V6Z" fill="#6F6F6F"/>
          </svg>
          未上架
        </div>
      </div>
    </div>
    <div class="provider-footer d-flex justify-content-center">
      <div class="link fs-14px" @click="buyFlowClicked">購物流程</div>
      <div class="fs-14px">｜</div>
      <div class="link fs-14px" @click="policyClicked">隱私權聲明</div>
      <div class="fs-14px">｜</div>
      <div class="link fs-14px" @click="exchangePolicyClicked">退換貨政策</div>
    </div>
    <transition name="fade">
      <FullScreenModal v-if="showServiceConfirm" title="服務內容" @back="closeServiceConfirmModal">
        <ServiceApplication :event-data="currentEventData" @apply="createOrder"></ServiceApplication>
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import { mapActions } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import ServiceApplication from "@/views/share/ServiceApplication.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ServiceProvider',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
      currentEventData: null,
      showServiceConfirm: false,
    };
  },
  props: {
    serviceData: {
      type: Object,
      required: true,
    },
  },
  components: {
    FullScreenModal,
    ServiceApplication,
  },
  computed: {
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions('event', ['setScheduledSerial']),

    async serviceConfirmClicked(data) {
      this.currentEventData = data;
      this.showServiceConfirm = true;
    },
    closeServiceConfirmModal() {
      this.showServiceConfirm = false;
    },
    getTypeName(type) {
      switch (type) {
        case 0:
          return '[寄售]';
        case 1:
          return '[捐贈]';
        default:
          return '資料載入錯誤';
      }
    },
    async createOrder(eventSerial) {
      await this.handleAsyncTask(async () => {
        const orderData = await this.$store.dispatch('api/postReviewOrderCreatePromise', { eventSerial: eventSerial });
        console.log(orderData);
        this.setScheduledSerial(orderData.serial);
        this.closeServiceConfirmModal();
        await this.$router.push({
          name: 'MarketPlaceHistory',
        });
      });
    },
    buyFlowClicked() {
      this.$store.dispatch('setBuyFlowShow', true);
    },
    policyClicked() {
      this.$store.dispatch('setPolicyShow', true);
    },
    exchangePolicyClicked() {
      this.$store.dispatch('setExchangeShow', true);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.service-provider {
  padding-bottom: 65.8px;
  .provider-image {
    position: relative;
    width: 100%;
    padding-top: 47%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .provider-info {
    padding: 1rem;
  }
  .provider-list {
  }
  .provider-item {
    gap: 1rem;
    padding: 1rem;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
    &.provider-item-test {
      background-color: #FFFAF4;
    }
    .item-image {
      width: 80px;
      height: 80px;
      border: 1px solid $color-border;
      border-radius: .75rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item-info {
      flex: 1 0;
    }
    .provider-test {
      position: absolute;
      top: .5rem;
      right: 1rem;
      font-size: .8rem;
      >svg {
        width: 10px;
      }
    }
  }
  .provider-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $color-gray-light;
    padding: 1.4rem;
    .link {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.service-provider {
}
</style>
  