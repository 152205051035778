<template>
  <div class="d3-percentage-donut-chart">
    <svg></svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";

export default {
  name: 'D3PercentageDonutChart',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    percentage: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100, // 確保百分比在 0-100 之間
    },
    size: {
      type: Number,
      default: 100, // 圖表直徑
    },
    thickness: {
      type: Number,
      default: 10, // 圖表圓環厚度
    },
    color: {
      type: String,
      default: "#14983D", // 圖表顏色
    },
    duration: {
      type: Number,
      default: 2000,
    },
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.drawChart();
    this.useObserver(
        this.$el,
        () => {
          this.isVisible = true;
          this.animateChart();
        }
    );
  },
  methods: {
    drawChart() {
      const { size, thickness, color } = this;
      const radius = size / 2;

      // 初始化SVG容器
      this.svg = d3
          .select(this.$el.querySelector("svg"))
          .attr("width", size)
          .attr("height", size)
          .append("g")
          .attr("transform", `translate(${radius}, ${radius})`);

      // 背景圓弧
      const bgColor = this.convertHexToRgba(color, 0.2);
      const backgroundArc = d3
          .arc()
          .innerRadius(radius - thickness)
          .outerRadius(radius)
          .startAngle(0)
          .endAngle(2 * Math.PI);

      this.svg
          .append("path")
          .attr("d", backgroundArc())
          .attr("fill", bgColor);

      // 動態圓弧（初始化為0%）
      this.foregroundArc = d3
          .arc()
          .innerRadius(radius - thickness)
          .outerRadius(radius)
          .startAngle(0)
          .cornerRadius(thickness / 2); // 添加圓角

      this.foregroundPath = this.svg
          .append("path")
          .attr("d", this.foregroundArc.endAngle(0))
          .attr("fill", color);

      // 添加百分比文字
      this.text = this.svg
          .append("text")
          .attr("text-anchor", "middle")
          .attr("dy", "0.35em")
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .text("0%");
    },
    animateChart() {
      const { percentage, duration } = this;

      const interpolate = d3.interpolate(0, percentage); // 插值計算從0到目標值

      d3.select(this.$el.querySelector("svg"))
          .transition()
          .duration(duration)
          .ease(d3.easeCubicOut)
          .tween("progress", () => {
            return (t) => {
              const currentPercentage = interpolate(t);

              // 更新圓弧
              this.foregroundArc.endAngle(
                  -(currentPercentage / 100) * 2 * Math.PI // 逆時針方向
              );
              this.foregroundPath.attr("d", this.foregroundArc);

              // 更新文字
              this.text.text(`${Math.round(currentPercentage)}%`);
            };
          });
    },
    convertHexToRgba(hex, alpha) {
      // 將十六進位顏色轉為 RGBA
      const hexColor = hex.replace("#", "");
      const r = parseInt(hexColor.substring(0, 2), 16);
      const g = parseInt(hexColor.substring(2, 4), 16);
      const b = parseInt(hexColor.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.d3-percentage-donut-chart {

}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.d3-percentage-donut-chart {

}
</style>
  