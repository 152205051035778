<template>
  <div class="d3-my-cloth-statistics" :style="{ width: width + 'px' }">
    <div class="chart">
      <div class="y-axis">
        <svg ref="chart-y-axis"></svg>
      </div>
      <div class="chart-body"
           :style="{ maxWidth: 86*data.length + 'px' }"
           @pointerdown="startDrag"
           @pointermove="drag"
           @pointerup="stopDrag"
           @pointerleave="stopDrag"
           ref="chartBody">
        <svg ref="chart"></svg>
      </div>
      <div class="y-axis">
        <svg ref="chart-y-axis-right"></svg>
      </div>
    </div>
    <div class="chart-mark-list">
      <div class="chart-mark-item">
        <div class="chart-mark-dot add"></div>
        <span class="fs-12px fw-bold">新增</span>
      </div>
      <div class="chart-mark-item">
        <div class="chart-mark-dot out"></div>
        <span class="fs-12px fw-bold">淘汰</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";

export default {
  name: 'D3MyClothStatistics',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 330,
    },
    height: {
      type: Number,
      default: 150,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    margin: {
      type: Object,
      default: () => ({ top: 16, right: 36, bottom: 36, left: 36 }),
    },
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.drawChart(false);
    this.useObserver(
        this.$el,
        () => {
          this.drawChart(true);
        }
    );
  },
  methods: {
    drawChart(animate) {
      const { data, width, height, duration, margin } = this;

      const numberOfYears = data.length;

      const chartWidth = 86 * numberOfYears;
      const chartHeight = height - margin.top - margin.bottom;

      // 繪製主圖表
      const chartContainer = d3.select(this.$refs.chart);
      chartContainer.selectAll("*").remove(); // 清空容器

      const svg = chartContainer
          .attr("width", chartWidth)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(0, ${margin.top})`);

      // 定義縮放比例
      const x0 = d3
          .scaleBand()
          .domain(data.map((d) => d.year))
          .range([0, chartWidth])
          .padding(0.2);

      const x1 = d3
          .scaleBand()
          .domain(["new", "out"])
          .range([0, x0.bandwidth()])
          .padding(0.2);

      const y = d3
          .scaleLinear()
          .domain([0, d3.max(data, (d) => Math.max(d.new, d.out))])
          .range([chartHeight, 0])
          .nice(5);

      // 定義顏色
      const color = d3.scaleOrdinal().domain(["new", "out"]).range(["#F7D88F", "#B1D8BD"]);

      // 繪製柱狀圖
      const bars = svg
          .selectAll(".group")
          .data(data)
          .join("g")
          .attr("transform", (d) => `translate(${x0(d.year)},0)`)
          .selectAll("rect")
          .data((d) => ["new", "out"].map((key) => ({ key, value: d[key] })))
          .join("rect")
          .attr("x", (d) => x1(d.key))
          .attr("width", x1.bandwidth())
          .attr("fill", (d) => color(d.key));

      if (animate) {
        bars
            .attr("y", chartHeight)
            .attr("height", 0)
            .transition()
            .duration(duration)
            .attr("y", (d) => y(d.value))
            .attr("height", (d) => chartHeight - y(d.value));
      } else {
        bars.attr("y", () => y(0)).attr("height", 0); // 初始靜止
      }

      // 繪製X軸
      const xAxisGroup = svg
          .append("g")
          .attr("transform", `translate(0,${chartHeight})`);

      // 定義 X 軸並繪製
      const xAxis = d3.axisBottom(x0).tickFormat((d) => `${d}`);
      xAxisGroup.call(xAxis);

      // 隱藏標線
      xAxisGroup.selectAll("line").style("display", "none");

      // 修改主軸線顏色並移除兩端延伸
      xAxisGroup.selectAll("path")
          .style("stroke", "#C6C6C6") // 設置主軸線顏色
          .attr("d", function () {
            const originalD = d3.select(this).attr("d"); // 獲取當前的 path 路徑
            // 移除垂直段
            let modifiedD = originalD.replace(/V\s*\d+/g, ""); // 去除垂直段
            // 修改M命令的y坐標，將其設置為期望的值（例如y=0）
            modifiedD = modifiedD.replace(/M\d+,(\d+)/, () => `M0,0`); // 這裡是將 y 坐標設置為 0
            return modifiedD; // 返回修改後的路徑
          });

      // 修改文字樣式
      xAxisGroup.selectAll("text")
          .style("font-size", "12px")
          .style("line-height", "14px")
          .style("font-weight", "bold")
          .style("fill", "#6F6F6F")
          .attr("dy", "0.5em");


      // 繪製 Y 軸，保持固定
      const yAxisSvg = d3.select(this.$refs["chart-y-axis"]);
      const yAxisSvgRight = d3.select(this.$refs["chart-y-axis-right"]);
      yAxisSvg.selectAll("*").remove();
      yAxisSvgRight.selectAll("*").remove(); // 清空Y軸容器

      // 在左側 Y 軸繪製
      const yAxisGroupLeft = yAxisSvg
          .attr("width", margin.left)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left - 1}, ${margin.top})`);

      // 定義左側 Y 軸
      const yAxis = d3.axisLeft(y)
          .tickFormat((d) => d === 0 ? "" : `${d}件`)  // 隱藏 0 的文字
          .ticks(5);

      yAxisGroupLeft.call(yAxis);

      // 隱藏標線和主軸線
      yAxisGroupLeft.selectAll("line").style("display", "none"); // 隱藏標線
      yAxisGroupLeft.selectAll("path").style("display", "none"); // 隱藏主軸線

      // 修改文字樣式
      yAxisGroupLeft.selectAll("text")
          .style("font-size", "12px")
          .style("fill", "#6F6F6F")
          .attr("dx", "0.4em"); // 設置字體樣式

      // 在右側 Y 軸繪製
      const yAxisGroupRight = yAxisSvgRight
          .attr("width", margin.right)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(14, ${margin.top})`); // 右側 Y 軸，稍微偏移

      yAxisGroupRight.call(yAxis);

      // 隱藏標線和主軸線
      yAxisGroupRight.selectAll("line").style("display", "none"); // 隱藏標線
      yAxisGroupRight.selectAll("path").style("display", "none"); // 隱藏主軸線

      // 修改右側 Y 軸的文字樣式
      yAxisGroupRight.selectAll("text")
          .style("font-size", "12px")
          .style("fill", "#6F6F6F")
          .style("text-anchor", "start"); // 讓文字靠左對齊

      // 滾動條滑動邏輯
      if ((width - margin.left - margin.right) < chartWidth && animate) {
        const chartElement = this.$refs.chartBody;
        const targetScrollLeft = chartElement.scrollWidth - chartElement.clientWidth;
        this.smoothScroll(chartElement, targetScrollLeft, (duration - 500));
      }
    },
    smoothScroll(element, target, duration) {
      const start = element.scrollLeft;
      const distance = target - start;
      const startTime = performance.now();

      function step(currentTime) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // 確保 progress 不超過 1
        element.scrollLeft = start + distance * progress;

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      }

      requestAnimationFrame(step);
    },
    // 開始拖曳
    startDrag(e) {
      this.isDown = true;
      this.startX = e.pageX - this.$refs.chartBody.offsetLeft;
      this.scrollLeft = this.$refs.chartBody.scrollLeft;
    },
    // 拖曳過程中
    drag(e) {
      if (!this.isDown) return; // 如果不是按下狀態，不處理
      e.preventDefault();
      const x = e.pageX - this.$refs.chartBody.offsetLeft;
      const walk = (x - this.startX) * 2; // 調整拖曳速度
      this.$refs.chartBody.scrollLeft = this.scrollLeft - walk;
    },
    // 停止拖曳
    stopDrag() {
      this.isDown = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.d3-my-cloth-statistics {
  .chart {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    .y-axis {
      width: 36px;
      position: relative;
      flex-shrink: 0; /* 防止壓縮 */
    }
    .chart-body {
      overflow-x: auto; /* 滾動條 */
      -ms-overflow-style: none; /* 適用於 IE/Edge */
      scrollbar-width: none; /* 適用於 Firefox */
      &::-webkit-scrollbar {
        display: none; /* 適用於 Chrome/Safari */
      }
    }
  }
  .chart-mark-list {
    max-width: 330px;
    display: flex;
    gap: 1rem;
    justify-content: center;
    .chart-mark-item {
      display: flex;
      align-items: center;
      gap: .5rem;
      .chart-mark-dot {
        width: .5rem;
        height: .5rem;
        background: $color-black;
        border-radius: 100%;
        &.add {
          background: #F7D88F;
        }
        &.out {
          background: #B1D8BD;
        }
        &.all {
          background: #E96D45;
        }
      }
      span {
        color: $typography-secondary-default;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.d3-my-cloth-statistics {

}
</style>
  