<template>
  <div class="custom-collapse">
    <div class="collapse-toggle" :class="{ active: isOpen }" @click="toggle">
      <slot name="toggle">
        <button>{{ isOpen ? "收起" : "展開" }}</button>
      </slot>
    </div>

    <div
        class="collapse-content"
        ref="content"
        :style="{ maxHeight: isOpen ? `${contentHeight}px` : '0' }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomCollapse',
  data() {
    return {
      isOpen: false,
      contentHeight: 0,
    };
  },
  props: {
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  async mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.content.scrollHeight;
    });
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      // this.$nextTick(() => {
      //   if (this.isOpen) {
      //     this.contentHeight = this.$refs.content.scrollHeight;
      //   }
      // });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-collapse {
  .collapse-toggle {
    cursor: pointer;
  }
  .collapse-content {
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-collapse {

}
</style>
  